import { Injectable } from "@angular/core";
import { SoundType } from "../models/sounds.models";

export const soundList: Map<SoundType, HTMLAudioElement> = new Map([
    [SoundType.PlannedMissionNotification, new Audio("../../assets/sounds/plans-notification.wav")],
    [SoundType.TacticalNotification, new Audio("../../assets/sounds/tactical-notification.wav")],
]);

@Injectable({
    providedIn: "root",
})
export class SoundService {
    public playSound(soundType: SoundType) {
        if (!soundList.has(soundType)) {
            return;
        }

        soundList.get(soundType)?.play();
    }

    public pauseSound(soundType: SoundType): void {
        if (!soundList.has(soundType)) {
            return;
        }

        soundList.get(soundType)?.pause();
    }
}
