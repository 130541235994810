<ng-container
    *ngrxLet="{
        isProcessing: isProcessing$,
        dtmAreas: dtmAreas$,
        routeData: routeData$,
        trajectories: trajectories$,
        alertList: alertList$,
        proceedingMissions: proceedingMissions$,
        checkinList: checkinList$,
        finishedCheckinList: finishedCheckinList$,
        incomingMissions: incomingMissions$,
        finishedMissions: finishedMissions$,
        violations: violations$,
        selectedOperationId: selectedOperationId$,
        isPanelFolded: isPanelFolded$,
        isActionButtonsPanelVisible: isActionButtonsPanelVisible$,
        isTemporaryZoneProcessing: isTemporaryZoneProcessing$,
        drawnEntitiesCount: drawnEntitiesCount$,
        activeMapAction: activeMapAction$,
        activeEntityStatus: activeEntityStatus$,
        mapEntity: mapEntity$,
        selectedMissionData: selectedMissionData$,
        selectedDtmName: selectedDtmName$
    } as vm"
>
    <div class="operational-situation">
        <div
            class="operational-situation-panel card-box"
            [class.action-bar-visible]="vm.isActionButtonsPanelVisible"
            [class.folded]="vm.isPanelFolded"
        >
            <div class="mission-details">
                <dtm-ui-loader-container [isShown]="vm.isTemporaryZoneProcessing">
                    <supervisor-shared-lib-temporary-zones
                        *ngIf="vm.selectedDtmName; else noSelectedDtmTemplate"
                        [displayForm]="vm.isActionButtonsPanelVisible"
                        [mapEntity]="vm.mapEntity"
                        [draftZoneList]="draftZoneList$ | ngrxPush"
                        [activeZoneList]="activeZoneList$ | ngrxPush"
                        [archiveZoneList]="archiveZoneList$ | ngrxPush"
                        [draftZoneDetails]="draftDetails$ | ngrxPush"
                        [selectedZoneId]="selectedZoneId$ | ngrxPush"
                        [zoneDetails]="zoneDetails$ | ngrxPush"
                        [isElevationProcessing]="isElevationProcessing$ | ngrxPush"
                        [isArchivedZonesFullyLoaded]="isArchivedZonesFullyLoaded$ | ngrxPush"
                        [elevation]="elevation$ | ngrxPush"
                        [selectedTab]="selectedTab$ | ngrxPush"
                        [cloneZoneData]="cloneZoneData$ | ngrxPush"
                        (editModeChange)="displayActionBar(true)"
                        (zoneFinish)="openFinishZoneConfirmationDialog($event)"
                        (draftSelect)="getDraftDetails($event)"
                        (zoneSelect)="getZoneDetails($event)"
                        (draftDelete)="deleteDraft($event)"
                        (zonePublish)="publishZone($event)"
                        (zoneZoom)="zoomArea($event)"
                        (tabChange)="getZoneListByTab($event)"
                        (loadMore)="loadMoreArchivedZones()"
                        (zonesFiltersChange)="filterZones($event, vm.selectedDtmName)"
                        (zoneClone)="clonePublishedZone($event)"
                    ></supervisor-shared-lib-temporary-zones>
                </dtm-ui-loader-container>
                <ng-template #noSelectedDtmTemplate>
                    <h2 class="no-dtm">
                        {{ "dtmSupOperationalSituation.operationalSituationContainer.selectDtmHeading" | transloco }}
                    </h2>
                </ng-template>
            </div>
            <div class="view-switcher">
                <button type="button" (click)="togglePanel()">
                    <dtm-ui-icon [name]="vm.isPanelFolded ? 'menu-unfold' : 'menu-fold'"></dtm-ui-icon>
                </button>
            </div>
        </div>
        <div class="operational-situation-map">
            <supervisor-shared-lib-message-slider
                [alarmList]="alarmList$ | ngrxPush"
                (alarmClose)="closeGlobalAlarm($event)"
            ></supervisor-shared-lib-message-slider>
            <div class="map" [class.map-actions-visible]="vm.isActionButtonsPanelVisible">
                <supervisor-shared-lib-dtm-area
                    (dtmSelect)="setupDtmDetails($event.name, true)"
                    [selectedAreaName]="vm.selectedDtmName"
                    [dtmAreas]="vm.dtmAreas"
                ></supervisor-shared-lib-dtm-area>
                <div class="map-actions" [@foldDown]="vm.isActionButtonsPanelVisible ? 'open' : 'closed'">
                    <dtm-map-actions-panel
                        *ngIf="vm.isActionButtonsPanelVisible"
                        [mode]="MapActionsPanelMode.SupervisorTemporaryZone"
                        [activeMapAction]="vm.activeMapAction"
                        [entitiesCount]="vm.drawnEntitiesCount"
                        [maxEntities]="1"
                        [activeEntityStatus]="vm.activeEntityStatus"
                        (selectMapAction)="processMapActionChange($event)"
                    >
                    </dtm-map-actions-panel>
                </div>
                <supervisor-shared-lib-mission-map
                    [routeData]="vm.routeData"
                    [isProcessing]="vm.isProcessing"
                    [trajectories]="vm.trajectories"
                    [violations]="vm.violations"
                    [zoomToRoute]="zoomToRoute$ | ngrxPush"
                    [area]="selectedZoneArea$ | ngrxPush"
                    [isZoneProcessing]="isTemporaryZoneProcessing$ | ngrxPush"
                    [initialViewGeometry]="initialViewGeometry"
                    [checkin]="selectedCheckin$ | ngrxPush"
                    [checkins]="vm.checkinList"
                    (visibleAreaChanged)="handleVisibleAreaChange($event)"
                    [zoneTimeSettingOptions]="{
                        available: [ZoneTimesSetting.Soon, ZoneTimesSetting.Current, ZoneTimesSetting.CustomTimeRange],
                        default: ZoneTimesSetting.Current
                    }"
                    (flightPositionUpdatesEnrich)="enrichFlightPositionUpdates($event)"
                ></supervisor-shared-lib-mission-map>
            </div>
        </div>
        <supervisor-shared-lib-sidebar
            [isSidebarFolded]="isOperationalSituationPanelFolded$ | ngrxPush"
            [title]="'dtmSupOperationalSituation.operationalSituation.sidebarHeaderTitle' | transloco"
            (panelFold)="closeOperationalSituationPanel()"
        >
            <supervisor-shared-lib-alert-list
                [alertList]="vm.alertList"
                (alertClose)="closeAlert($event)"
            ></supervisor-shared-lib-alert-list>
            <dtm-ui-loader-container [isShown]="vm.isProcessing">
                <supervisor-shared-lib-proceeding-missions-list
                    [proceedingMissions]="vm.proceedingMissions"
                    [checkinList]="vm.checkinList"
                    [selectedOperationId]="vm.selectedOperationId"
                    [currentMissionPlanData]="currentMissionPlanData$ | ngrxPush"
                    [selectedMission]="vm.selectedMissionData"
                    [selectedMissionSegmentStatuses]="selectedMissionSegmentStatuses$ | ngrxPush"
                    [zones]="collisionZones$ | ngrxPush"
                    [currentPlanAnalysisStatus]="currentPlanAnalysisStatus$ | ngrxPush"
                    (missionReject)="tryRejectMission($event, MissionListType.Proceeding)"
                    (missionTimeChange)="tryChangeMissionTime($event)"
                    (missionSelect)="selectMission($event)"
                    (missionRoadZoom)="zoomToRoute($event)"
                    (checkinSelect)="selectCheckin($event)"
                    (checkinZoom)="zoomToCheckin($event)"
                    (zoneSelect)="selectZone($event)"
                >
                </supervisor-shared-lib-proceeding-missions-list>
                <supervisor-shared-lib-other-missions
                    [incomingMissions]="vm.incomingMissions"
                    [finishedMissions]="vm.finishedMissions"
                    [selectedOperationId]="vm.selectedOperationId"
                    [currentMissionPlanData]="currentMissionPlanData$ | ngrxPush"
                    [selectedMission]="vm.selectedMissionData"
                    [checkinList]="vm.finishedCheckinList"
                    [zones]="collisionZones$ | ngrxPush"
                    [currentPlanAnalysisStatus]="currentPlanAnalysisStatus$ | ngrxPush"
                    (missionReject)="tryRejectMission($event, MissionListType.Incoming)"
                    (missionTimeChange)="tryChangeMissionTime($event)"
                    (missionSelected)="selectMission($event)"
                    (missionRoadZoom)="zoomToRoute($event)"
                    (zoneSelect)="selectZone($event)"
                ></supervisor-shared-lib-other-missions>
            </dtm-ui-loader-container>
        </supervisor-shared-lib-sidebar>
    </div>
    <div class="bottom-actions-bar" @slideIn *ngIf="vm.isActionButtonsPanelVisible">
        <button class="button-secondary" type="button" (click)="openConfirmationDialog()">
            {{ "dtmSupOperationalSituation.operationalSituation.cancelButtonLabel" | transloco }}
        </button>
        <button class="button-primary save-button" type="button" [disabled]="!vm.mapEntity" (click)="addZone()" data-testid="next-button">
            <dtm-ui-loader [diameter]="20" *ngIf="vm.isTemporaryZoneProcessing"></dtm-ui-loader>
            <span [class.is-loading]="vm.isTemporaryZoneProcessing">
                {{ "dtmSupOperationalSituation.operationalSituation.saveDraftButtonLabel" | transloco }}
            </span>
        </button>
    </div>
</ng-container>
