import { HttpClient, HttpContext, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    AirspaceElementResponseBody,
    GEO_ZONES_ENDPOINTS,
    GeoZonesEndpoints,
    SearchAirspaceElementsRequestBody,
    convertAirspaceElementResponseBodyToAirspaceElement,
} from "@dtm-frontend/shared/map/geo-zones";
import { AuthorityAcceptationStatus, MissionPlanAnalysisStatus, MissionPlanDataAndCapabilities } from "@dtm-frontend/shared/mission";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { SKIP_NOT_FOUND_HTTP_INTERCEPTOR, StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Observable, map, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
    MissionPlanDataResponseBody,
    MissionPlanVerificationResponseBody,
    convertMissionPlanDataResponseBodyToMissionPlanDataAndCapabilities,
    transformMissionPlanVerificationResponseBodyToMissionPlanAnalysisStatus,
} from "../../shared/converters/plan-analysis.converters";
import { MissionsEvents } from "../../shared/models/shared-supervisor-client.models";
import {
    Mission,
    MissionFilters,
    MissionProcessingPhase,
    NearbyMissionFilters,
    NoteData,
    PhasePayloadData,
    PlannedMissionErrorType,
    PriorityPayloadData,
} from "../models/mission.models";
import { PLANNED_MISSION_ENDPOINTS, PlannedMissionEndpoints } from "../planned-missions.tokens";
import {
    MissionPlanListResponseBody,
    MissionPlanRouteResponseBody,
    convertMissionFiltersToHttpParams,
    convertMissionPlanResponseBodyToMissionList,
    convertMissionPlanRouteResponseBodyToMissionPlanRoute,
    convertNearbyMissionFiltersToHttpParams,
    convertUtmPlanFiltersToHttpParams,
    transformChangeMissionPhaseError,
} from "./mission.converters";

@Injectable()
export class PlannedMissionApiService {
    constructor(
        private readonly httpClient: HttpClient,
        @Inject(PLANNED_MISSION_ENDPOINTS) private readonly endpoints: PlannedMissionEndpoints,
        @Inject(GEO_ZONES_ENDPOINTS) private readonly geoZonesEndpoints: GeoZonesEndpoints,
        private readonly websocketService: WebsocketService
    ) {}

    public startPlannedMissionsWatch(): Observable<unknown> {
        return this.websocketService.watchTopic(this.endpoints.dtmMissionsWatch, [MissionsEvents.ManualPlanVerificationSubmittedEvent]);
    }

    public startNearbyMissionsWatch(planId: string): Observable<unknown> {
        return this.websocketService.watchTopic(`${this.endpoints.nearbyMissionPlansWatch}/?planId=${planId}`, [
            MissionsEvents.MissionAcceptedEvent,
        ]);
    }

    // TODO:DTM-5282  remove this method after DTM supervisor will be removed
    /**
     * @deprecated temporary solution to work with DTM supervisor
     */
    public getMissionList(query: MissionFilters, missionPhase: MissionProcessingPhase): Observable<Mission[]> {
        const params: HttpParams = convertMissionFiltersToHttpParams(query, missionPhase);

        return this.httpClient.get<MissionPlanListResponseBody>(this.endpoints.getPlannedMission, { params }).pipe(
            map((response) => convertMissionPlanResponseBodyToMissionList(response)),
            catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotGetMissionList })))
        );
    }

    public getPlanList(query: MissionFilters, acceptationStatus: AuthorityAcceptationStatus): Observable<Mission[]> {
        const params: HttpParams = convertUtmPlanFiltersToHttpParams(query, acceptationStatus);

        return this.httpClient.get<MissionPlanListResponseBody>(this.endpoints.getPlannedMission, { params }).pipe(
            map((response) => convertMissionPlanResponseBodyToMissionList(response)),
            catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotGetMissionList })))
        );
    }

    public changeMissionPhase({
        systemVerificationId,
        missionPhase,
        comment,
        reviewerUnitId,
    }: PhasePayloadData): Observable<MissionProcessingPhase> {
        const endpoint: string =
            missionPhase === MissionProcessingPhase.Accepted ? this.endpoints.missionAcceptance : this.endpoints.missionRejection;

        return this.httpClient
            .post<MissionProcessingPhase>(StringUtils.replaceInTemplate(endpoint, { systemVerificationId }), { comment, reviewerUnitId })
            .pipe(catchError(() => throwError(() => transformChangeMissionPhaseError(missionPhase))));
    }

    public getMissionRoute(routeId: string): Observable<MissionPlanRoute> {
        return this.httpClient
            .get<MissionPlanRouteResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getMissionRoute, { routeId }))
            .pipe(
                map((response) => convertMissionPlanRouteResponseBodyToMissionPlanRoute(response)),
                catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotGetMissionRoute })))
            );
    }

    public updateMissionPriority({ systemVerificationId, priority }: PriorityPayloadData) {
        return this.httpClient
            .put(StringUtils.replaceInTemplate(this.endpoints.updateMissionPriority, { systemVerificationId }), {
                flightPriority: priority,
            })
            .pipe(catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotUpdateMissionPriority }))));
    }

    public getNearbyMissions(planId: string, nearbyMissionFilters?: NearbyMissionFilters): Observable<Mission[]> {
        let params: HttpParams = new HttpParams();

        if (nearbyMissionFilters) {
            params = convertNearbyMissionFiltersToHttpParams(planId, nearbyMissionFilters);
        }

        params = params.set("includeRouteSections", true);
        params = params.set("planId", planId);

        return this.httpClient.get<MissionPlanListResponseBody>(this.endpoints.getNearbyMissions, { params }).pipe(
            map((response) => convertMissionPlanResponseBodyToMissionList(response)),
            map((response) => response.filter((mission) => mission.phase === MissionProcessingPhase.Accepted)),
            catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotGetNearbyMissionList })))
        );
    }

    public updateSupervisorNote({ message, systemVerificationId }: NoteData): Observable<void> {
        return this.httpClient
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.updateSupervisorNote, { systemVerificationId: systemVerificationId }), {
                note: message,
            })
            .pipe(catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotUpdateSupervisorNote }))));
    }

    public getRequestPilotAttachment(attachmentId: string, selectedPlanId: string) {
        return this.httpClient
            .get(StringUtils.replaceInTemplate(this.endpoints.getFile, { fileId: attachmentId, planId: selectedPlanId }), {
                responseType: "blob",
            })
            .pipe(catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotUpdateSupervisorNote }))));
    }

    public getMissionPlanData(planId: string): Observable<MissionPlanDataAndCapabilities> {
        return this.httpClient
            .get<MissionPlanDataResponseBody>(StringUtils.replaceInTemplate(this.endpoints.missionManagement, { planId }))
            .pipe(
                map((response) => convertMissionPlanDataResponseBodyToMissionPlanDataAndCapabilities(response)),
                catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotGetMissionPlanData })))
            );
    }

    public searchAirspaceElements(options: Partial<SearchAirspaceElementsRequestBody>) {
        return this.httpClient.post<AirspaceElementResponseBody>(this.geoZonesEndpoints.searchAirspaceElements, options).pipe(
            map((response) => convertAirspaceElementResponseBodyToAirspaceElement(response, options.scope?.endTime)),
            catchError(() => throwError(() => ({ type: PlannedMissionErrorType.CannotGetAirspaceElements })))
        );
    }

    public getCurrentMissionPlanVerification(planId: string): Observable<MissionPlanAnalysisStatus> {
        return this.httpClient
            .get<MissionPlanVerificationResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.getMissionPlanVerification, { planId }),
                { context: new HttpContext().set(SKIP_NOT_FOUND_HTTP_INTERCEPTOR, true) }
            )
            .pipe(
                map((response) => transformMissionPlanVerificationResponseBodyToMissionPlanAnalysisStatus(response)),
                catchError(() => throwError(() => ({ type: PlannedMissionErrorType.Unknown })))
            );
    }
}
