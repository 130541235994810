import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { MissionType } from "@dtm-frontend/shared/mission";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { TacticalMissionUtils } from "../../../shared/utils/tactical-mission-utils";
import { WorkspacePermission } from "../../../sup-user";
import { Mission, NeighbourhoodType, PriorityPayloadData } from "../../models/mission.models";

const DEFAULT_PRIORITY = 9;

interface MissionInfoPanelComponentState {
    isExpanded: boolean;
    isSelected: boolean;
    mission: Mission | undefined;
    shouldChangeBasicMissionDetails: boolean;
    isPlanRouteProcessing: boolean;
    canEdit: boolean;
    workspacePermissions: WorkspacePermission[];
}

@Component({
    selector: "supervisor-shared-lib-mission-info-panel[mission]",
    templateUrl: "./mission-info-panel.component.html",
    styleUrls: ["./mission-info-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MissionInfoPanelComponent {
    @Input() public set mission(value: Mission | undefined) {
        this.localStore.patchState({ mission: value });
    }
    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value), isExpanded: coerceBooleanProperty(value) });
    }
    @Input() public set changeBasicDetailsLayout(value: BooleanInput) {
        this.localStore.patchState({ shouldChangeBasicMissionDetails: coerceBooleanProperty(value) });
    }
    @Input() public set isPlanRouteProcessing(value: BooleanInput) {
        this.localStore.patchState({ isPlanRouteProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set canEdit(value: BooleanInput) {
        this.localStore.patchState({ canEdit: coerceBooleanProperty(value) });
    }
    @Input() public set workspacePermissions(value: WorkspacePermission[]) {
        this.localStore.patchState({ workspacePermissions: value });
    }

    @Output() public readonly accept = new EventEmitter<void>();
    @Output() public readonly reject = new EventEmitter<void>();
    @Output() public readonly priorityEdit = new EventEmitter<PriorityPayloadData>();

    @Output() public readonly cancel = new EventEmitter<void>();
    @Output() public readonly save = new EventEmitter<void>();

    @Output() public readonly routeZoom = new EventEmitter<string | undefined>();

    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");
    protected readonly canEdit$ = this.localStore.selectByKey("canEdit");
    protected readonly isPlanRouteProcessing$ = this.localStore.selectByKey("isPlanRouteProcessing");
    protected readonly shouldChangeBasicMissionDetails$ = this.localStore.selectByKey("shouldChangeBasicMissionDetails");
    protected readonly mission$ = this.localStore.selectByKey("mission").pipe(RxjsUtils.filterFalsy());
    protected readonly isStartTimeExceeded$ = this.localStore.selectByKey("mission").pipe(
        RxjsUtils.filterFalsy(),
        map(({ flightStartAt }) => new Date(flightStartAt) < new Date())
    );
    protected readonly missionTimeRange$ = this.localStore.selectByKey("mission").pipe(
        RxjsUtils.filterFalsy(),
        map((mission) => TacticalMissionUtils.convertMissionToMissionTimeRange(mission))
    );
    protected readonly workspacePermissions$ = this.localStore.selectByKey("workspacePermissions");
    protected readonly MissionType = MissionType;
    protected readonly NeighbourhoodType = NeighbourhoodType;
    protected readonly DEFAULT_PRIORITY = DEFAULT_PRIORITY;

    protected selectedZoomRouteId: string | undefined;

    constructor(private readonly localStore: LocalComponentStore<MissionInfoPanelComponentState>, private elementRef: ElementRef) {
        this.localStore.setState({
            isExpanded: false,
            isSelected: false,
            mission: undefined,
            shouldChangeBasicMissionDetails: false,
            isPlanRouteProcessing: false,
            canEdit: false,
            workspacePermissions: [],
        });
    }

    protected toggle(): void {
        this.localStore.patchState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
    }

    protected toggleZoom(event: Event, routeId: string) {
        event.stopPropagation();

        if (routeId === this.selectedZoomRouteId) {
            this.selectedZoomRouteId = undefined;
            this.routeZoom.emit();

            return;
        }

        this.selectedZoomRouteId = routeId;
        this.routeZoom.emit(routeId);
    }

    protected isPriorityEditable(workspacePermissions: WorkspacePermission[]): boolean {
        return workspacePermissions.includes(WorkspacePermission.PermissionManagement);
    }
}
