import { DecimalPipe } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnDestroy, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
    CameraHelperService,
    FlightPositionUpdaterService,
    MapActionType,
    MapActionWithPayload,
    MapActionsPanelMode,
} from "@dtm-frontend/shared/map/cesium";
import { FlightTrackingActions, FlightTrackingErrorType, FlightTrackingState } from "@dtm-frontend/shared/map/flight-tracking";
import { WeatherActions, WeatherViewMode } from "@dtm-frontend/shared/map/geo-weather";
import { AirspaceElement, GeoZonesActions, GeoZonesState, ZoneTimesSetting } from "@dtm-frontend/shared/map/geo-zones";
import {
    ConfirmationDialogComponent,
    GeoJSON,
    MissionSegmentStatus,
    RouteData,
    Trajectory,
    TrajectoryPosition,
    UIActions,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import {
    FlightViolationEvent,
    FlightViolationUpdaterService,
    MissionData,
    MissionStatus,
    TacticalService,
    Violation,
    ViolationNotification,
} from "@dtm-frontend/shared/ui/tactical";
import {
    AnimationUtils,
    DEFAULT_DEBOUNCE_TIME,
    FunctionUtils,
    LocalComponentStore,
    ObjectUtils,
    RxjsUtils,
    SpatialUtils,
} from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, Store, ofActionDispatched } from "@ngxs/store";
import { BBox } from "@turf/helpers";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, combineLatestWith, filter, firstValueFrom, lastValueFrom, map, of, switchMap, tap } from "rxjs";
import { debounceTime, first } from "rxjs/operators";
import { GlobalAlarmActions } from "../../../global-alarm/state/global-alarm.actions";
import { GlobalAlarmState } from "../../../global-alarm/state/global-alarm.state";
import { DtmName, Mission } from "../../../planned-missions/models/mission.models";
import { MissionState } from "../../../planned-missions/state/mission.state";
import { Alarm } from "../../../shared/models/shared-supervisor-client.models";
import { fallbackDtmArea } from "../../../shared/utils/dtm-bbox-features-map";
import { SupUserActions, SupUserState, WorkspacePermission } from "../../../sup-user";
import {
    AlertPayloadData,
    Checkin,
    MissionListType,
    ProceedingMission,
    TemporaryZoneErrorType,
} from "../../models/operational.situation.models";
import { TemporaryZoneTab, TemporaryZoneType, ZoneFilters } from "../../models/temporary-zones.models";
import { OperationalSituationMapService } from "../../services/operational-situation-map.service";
import { OperationalSituationActions } from "../../state/operational-situation.actions";
import { OperationalSituationState } from "../../state/operational-situation.state";
import { TemporaryZonesActions } from "../../state/temporary-zones.actions";
import { TemporaryZonesState } from "../../state/temporary-zones.state";
import { MissionTimeChangeDialogComponent } from "../proceeding-mission-tile/mission-time-change-dialog/mission-time-change-dialog.component";
import { RejectMissionDialogComponent } from "../proceeding-mission-tile/reject-mission-dialog/reject-mission-dialog.component";
import { TemporaryZonesComponent } from "../temporary-zones/temporary-zones.component";

/* eslint-disable @typescript-eslint/no-explicit-any*/
declare const Cesium: any; // TODO: DTM-966

interface OperationalSituationContainerComponentState {
    isOperationalSituationPanelFolded: boolean;
    currentDtmName: string | undefined;
    trajectories: Map<string, Trajectory[]> | undefined;
    violations: Map<string, Violation | undefined> | undefined;
    isPanelFolded: boolean;
    isActionButtonsPanelVisible: boolean;
    selectedDtmName: string | undefined;
    selectedZoneId: string | undefined;
    selectedTrackerId: string | undefined;
    selectedMissionData: ProceedingMission | undefined;
}

const VIOLATION_PRIORITY: Violation[] = [
    Violation.UavEnteredForeignSafetyArea,
    Violation.UavOutsideStartingFlightZone,
    Violation.UavLeftOwnSafetyArea,
    Violation.UavLeftOwnFlightArea,
];

const MISSION_NAME_TRANSLATION_KEY = "dtmSupOperationalSituation.proceedingMissionTile.missionLabel";

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-operational-situation-container",
    templateUrl: "./operational-situation-container.component.html",
    styleUrls: ["./operational-situation-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, DecimalPipe],
    animations: [AnimationUtils.slideInAnimation(), AnimationUtils.foldAnimation()],
})
export class OperationalSituationContainerComponent implements OnDestroy, AfterViewInit {
    @ViewChild(TemporaryZonesComponent) private readonly temporaryZonesComponent!: TemporaryZonesComponent;

    protected readonly dtmAreas$ = this.store.select(MissionState.dtmAreas);
    protected readonly isProcessing$ = this.store.select(OperationalSituationState.isProcessing);
    protected readonly alertList$ = this.store.select(OperationalSituationState.alertList);
    protected readonly proceedingMissions$ = this.store.select(OperationalSituationState.proceedingMissions);
    protected readonly checkinList$ = this.store.select(OperationalSituationState.checkinList);
    protected readonly finishedCheckinList$ = this.store.select(OperationalSituationState.finishedCheckinList);
    protected readonly draftZoneList$ = this.store.select(TemporaryZonesState.draftZoneList);
    protected readonly archiveZoneList$ = this.store.select(TemporaryZonesState.archiveZoneList);
    protected readonly activeZoneList$ = this.store.select(TemporaryZonesState.activeZoneList);
    protected readonly cloneZoneData$ = this.store.select(TemporaryZonesState.cloneZoneData);
    protected readonly draftDetails$ = this.store.select(TemporaryZonesState.draftDetails);
    protected readonly zoneDetails$ = this.store.select(TemporaryZonesState.zoneDetails);
    protected readonly selectedZoneArea$ = this.store.select(TemporaryZonesState.selectedZoneArea);
    protected readonly selectedTab$ = this.store.select(TemporaryZonesState.selectedTab);
    protected readonly isElevationProcessing$ = this.store.select(TemporaryZonesState.isElevationProcessing);
    protected readonly elevation$ = this.store.select(TemporaryZonesState.elevation);
    protected readonly incomingMissions$ = this.store.select(OperationalSituationState.incomingMissions);
    protected readonly finishedMissions$ = this.store.select(OperationalSituationState.finishedMissions);
    protected readonly selectedMissionData$ = this.store.select(OperationalSituationState.selectedMissionData);
    protected readonly currentMissionPlanData$ = this.store.select(OperationalSituationState.currentMissionPlanData);
    protected readonly currentPlanAnalysisStatus$ = this.store.select(MissionState.currentPlanAnalysisStatus);
    protected readonly collisionZones$ = this.store.select(GeoZonesState.customZonesLayersData);
    protected readonly alarmList$ = this.store.select(GlobalAlarmState.alarms);
    protected readonly isTemporaryZoneProcessing$ = this.store.select(TemporaryZonesState.isProcessing);
    protected readonly isArchivedZonesFullyLoaded$ = this.store.select(TemporaryZonesState.isArchivedZonesFullyLoaded);
    protected readonly currentMissionsData$ = this.store.select(OperationalSituationState.currentMissionsData);
    protected readonly selectedCheckin$ = this.store.select(OperationalSituationState.selectedCheckin);
    protected readonly selectedOperationId$ = this.store.select(OperationalSituationState.selectedOperationId);
    protected readonly deactivatedSectionsInfo$ = this.store
        .select(OperationalSituationState.deactivatedSectionsInfo)
        .pipe(RxjsUtils.filterFalsy());
    protected readonly workspacePermissions$ = this.store
        .select(SupUserState.selectedWorkspace)
        .pipe(map((workspace) => workspace?.permissions));

    protected readonly isPanelFolded$ = this.localStore.selectByKey("isPanelFolded");
    protected readonly isActionButtonsPanelVisible$ = this.localStore.selectByKey("isActionButtonsPanelVisible");
    protected readonly isOperationalSituationPanelFolded$ = this.localStore.selectByKey("isOperationalSituationPanelFolded");
    protected readonly trajectories$ = this.localStore.selectByKey("trajectories");
    protected readonly violations$ = this.localStore.selectByKey("violations");
    protected readonly mapEntity$ = this.operationalSituationMapService.editorContent$.pipe(map((entities) => entities[0]));
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly selectedTrackerId$ = this.localStore.selectByKey("selectedTrackerId");
    protected readonly selectedDtmName$ = this.store.select(OperationalSituationState.selectedDtmName);

    protected readonly missions$ = this.prepareMissionStream();

    protected readonly routeData$: Observable<RouteData<MissionData> | undefined>;
    protected readonly MissionListType = MissionListType;
    protected readonly MapActionsPanelMode = MapActionsPanelMode;
    protected readonly ZoneTimesSetting = ZoneTimesSetting;
    private readonly tacticalService: TacticalService;

    protected readonly zoomToRoute$ = new Subject<RouteData<Mission>>();
    protected readonly flights$ = this.flightPositionUpdater.flightPositionUpdate$;
    private readonly activeViolations = new Map<string, Set<Violation>>();
    protected readonly initialViewGeometry = fallbackDtmArea;

    protected readonly drawnEntitiesCount$ = this.operationalSituationMapService.editorContent$.pipe(
        map((content) => (content && content.length) ?? 0)
    );
    protected readonly activeMapAction$ = this.operationalSituationMapService.activeMapAction$;
    protected readonly activeEntityStatus$ = this.operationalSituationMapService.activeEntityStatus$;

    protected readonly isMeasureToolEnabled$ = this.activeMapAction$.pipe(map((action) => action === MapActionType.None));

    protected selectedMissionSegmentStatuses$: Observable<MissionSegmentStatus[]> | undefined;

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<OperationalSituationContainerComponentState>,
        private readonly transloco: TranslocoService,
        private readonly toastr: ToastrService,
        private readonly matDialog: MatDialog,
        private readonly flightPositionUpdater: FlightPositionUpdaterService,
        private readonly flightViolationUpdaterService: FlightViolationUpdaterService,
        private readonly operationalSituationMapService: OperationalSituationMapService,
        private readonly translationHelper: TranslationHelperService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly cameraHelperService: CameraHelperService,
        private readonly actions: Actions,
        private readonly translationHelperService: TranslationHelperService,
        @Inject(LOCALE_ID) private readonly locale: string,
        private decimalPipe: DecimalPipe
    ) {
        this.localStore.setState({
            isOperationalSituationPanelFolded: true,
            currentDtmName: undefined,
            trajectories: undefined,
            violations: undefined,
            isPanelFolded: true,
            isActionButtonsPanelVisible: false,
            selectedZoneId: undefined,
            selectedDtmName: undefined,
            selectedTrackerId: undefined,
            selectedMissionData: undefined,
        });
        this.store.dispatch([
            new WeatherActions.ChangeWeatherVisibility(WeatherViewMode.Basic),
            new GeoZonesActions.SetCustomZonesVisibility(true),
        ]);

        this.tacticalService = new TacticalService({
            caller: this,
            missionDataStream: this.selectedMissionData$,
            otherMissionsStream: this.currentMissionsData$,
            refreshNearbyMissionListCallback: () => undefined,
            sectionDeactivationEventsStream: this.deactivatedSectionsInfo$,
            selectedNearbyMissionStream: of(undefined),
        });

        this.routeData$ = this.tacticalService.initRouteData();
        this.selectedMissionSegmentStatuses$ = this.routeData$.pipe(map((routeData) => routeData?.sectionStatuses ?? []));

        this.currentMissionsData$.pipe(untilDestroyed(this)).subscribe((mission) => {
            const trajectories = mission.reduce(
                (trajectoriesMap, missionData) => trajectoriesMap.set(missionData.missionId, missionData.trajectories),
                new Map()
            );

            this.localStore.patchState({ trajectories });
        });

        this.updateTrajectoriesOnFlightUpdates();
        this.watchForViolationsUpdates();
        this.watchEditorContent();
        this.watchDispatchedActions();
        this.watchFlightTrackingErrors();
    }

    public async ngAfterViewInit() {
        // TODO:  DTM-5188 - remove waitForTranslation when DatesDiffPipe translation problem will be fixed
        this.translationHelperService
            .waitForTranslation("dtmUi.timeLabels.days")
            .pipe(first(), untilDestroyed(this))
            .subscribe(() => this.store.dispatch(GeoZonesActions.EnableAllGeoZones));

        const isDtmPreselected = await this.selectOperationalSituation();

        if (isDtmPreselected) {
            return;
        }

        const dtmNames = await firstValueFrom(
            this.store.select(MissionState.dtmAreas).pipe(
                first(FunctionUtils.isTruthy),
                map((areas) => areas.map((area) => area.name))
            )
        );

        this.flyToCombinedDtmAreas(dtmNames);
    }

    private async flyToCombinedDtmAreas(dtmNames: string[]) {
        await firstValueFrom(this.store.dispatch(new SupUserActions.GetUserDtmZonesData(dtmNames)));
        const dtmAreas = this.store.selectSnapshot(SupUserState.dtmZonesData).filter((zone) => dtmNames.includes(zone.designator ?? ""));
        if (!dtmAreas.length) {
            return;
        }
        const combinedDtmAreas = SpatialUtils.union(dtmAreas.map((area) => area.geometry));
        this.cameraHelperService.flyToGeoJSON(combinedDtmAreas ?? fallbackDtmArea);
    }

    public ngOnDestroy(): void {
        this.store.dispatch([
            OperationalSituationActions.ClearMapEntities,
            OperationalSituationActions.StopSectionDeactivationEventWatch,
            OperationalSituationActions.ClearMissionAnalysisAndCapabilities,
            TemporaryZonesActions.ClearSelectedZoneArea,
            new OperationalSituationActions.UpdateSelectedDtmName(undefined),
            FlightTrackingActions.CleanUpFlightTrackingState,
            OperationalSituationActions.StopFlightPositionUpdatesWatch,
            OperationalSituationActions.StopTacticalMissionsWatch,
        ]);

        this.operationalSituationMapService.clearMap();
    }

    protected filterZones({ zoneFiltersData, zoneType }: ZoneFilters, dtmName: DtmName): void {
        this.store.dispatch(new TemporaryZonesActions.UpdateZoneFiltersData(zoneFiltersData, zoneType));

        switch (zoneType) {
            case TemporaryZoneType.Zone:
                this.store.dispatch(new TemporaryZonesActions.GetActiveZones(dtmName));
                break;

            case TemporaryZoneType.Draft:
                this.store.dispatch(new TemporaryZonesActions.GetDraftZones(dtmName));
                break;

            case TemporaryZoneType.Archive:
                this.store.dispatch(new TemporaryZonesActions.GetArchiveZones(dtmName));
                break;

            default:
                throw new Error(`Invalid action type: ${zoneType}`);
        }
    }

    protected clonePublishedZone(zoneId: string): void {
        this.store
            .dispatch(new TemporaryZonesActions.ClonePublishedZone(zoneId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.localStore.patchState({ isActionButtonsPanelVisible: true });

                const area = this.store.selectSnapshot(TemporaryZonesState.cloneZoneData)?.area;

                if (!area?.properties) {
                    return;
                }

                this.operationalSituationMapService.createEntityFromArea(area);
            });
    }

    protected zoomArea(coordinates: GeoJSON): void {
        this.cameraHelperService.flyToGeoJSON(coordinates);
    }

    protected openFinishZoneConfirmationDialog(zoneId: string): void {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.finishZoneDialogTitleText"),
                confirmationText: this.transloco.translate(
                    "dtmSupOperationalSituation.operationalSituationContainer.zoneDialogConfirmationText"
                ),
                declineButtonLabel: this.transloco.translate(
                    "dtmSupOperationalSituation.operationalSituationContainer.zoneDialogDeclineButtonLabel"
                ),
                confirmButtonLabel: this.transloco.translate(
                    "dtmSupOperationalSituation.operationalSituationContainer.zoneDialogConfirmButtonLabel"
                ),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(filter(FunctionUtils.isTruthy), untilDestroyed(this))
            .subscribe(() => {
                this.finishZone(zoneId);
            });
    }

    protected async setupDtmDetails(dtmName: DtmName, shouldReplacePath = false) {
        this.managePanelsVisibility();
        this.watchTacticalMissions(dtmName);
        this.getMissionAlerts(dtmName);
        this.getMissions(dtmName);
        this.getCheckins(dtmName);
        this.getZones(dtmName);

        if (shouldReplacePath) {
            this.router.navigate(["."], {
                relativeTo: this.activatedRoute,
                queryParams: { dtmName },
                replaceUrl: true,
            });
        }

        this.localStore.patchState({ selectedDtmName: dtmName });
        await firstValueFrom(this.store.dispatch(new SupUserActions.GetUserDtmZonesData([dtmName])));
        const dtmArea = this.store.selectSnapshot(SupUserState.dtmZoneData(dtmName));
        this.cameraHelperService.flyToGeoJSON(dtmArea?.geometry ?? fallbackDtmArea);
        this.store.dispatch(new OperationalSituationActions.UpdateSelectedDtmName(dtmName));
    }

    protected closeOperationalSituationPanel(): void {
        this.localStore.patchState({ isOperationalSituationPanelFolded: true });
    }

    protected getZoneListByTab(zoneTab: TemporaryZoneTab): void {
        const dtmName = this.store.selectSnapshot(OperationalSituationState.selectedDtmName);

        if (!dtmName) {
            return;
        }

        switch (zoneTab) {
            case TemporaryZoneTab.CurrentlyValid:
                this.getActiveZones(dtmName);
                break;
            case TemporaryZoneTab.Drafts:
                this.getTemporaryZones(dtmName);
                break;
            case TemporaryZoneTab.Archival:
                this.getArchivedZones(dtmName);
                break;
            default:
                return;
        }

        this.localStore.patchState({ selectedZoneId: undefined });
        this.store.dispatch(TemporaryZonesActions.ClearSelectedZoneArea);
    }

    protected loadMoreArchivedZones(): void {
        const dtmName = this.store.selectSnapshot(OperationalSituationState.selectedDtmName);

        if (!dtmName) {
            return;
        }

        this.store
            .dispatch(new TemporaryZonesActions.LoadMoreArchivedZones(dtmName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.loadArchiveZoneError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.loadMoreZonesErrorMessage")
                    );
                }
            });
    }

    protected deleteDraft(draftId: string): void {
        this.store
            .dispatch(new TemporaryZonesActions.DeleteDraft(draftId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.deleteDraftError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.deleteDraftZoneErrorMessage")
                    );

                    return;
                }

                this.toastr.success(
                    this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.deleteDraftZoneSuccessMessage")
                );

                this.localStore.patchState({ selectedZoneId: undefined });
                this.store.dispatch(TemporaryZonesActions.ClearSelectedZoneArea);
            });
    }

    protected publishZone(draftId: string): void {
        this.store.dispatch(new TemporaryZonesActions.PublishZone(draftId)).subscribe(() => {
            const error = this.store.selectSnapshot(TemporaryZonesState.publishZoneError);

            if (error) {
                this.toastr.error(
                    this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.publishZoneErrorMessage")
                );

                return;
            }

            this.toastr.success(
                this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.publishZoneSuccessMessage")
            );
        });
    }

    protected async addZone(): Promise<void> {
        const temporaryZoneValue = this.temporaryZonesComponent.temporaryZoneControl.value;
        const mapEntity = await lastValueFrom(
            this.operationalSituationMapService.editorContent$.pipe(
                first(),
                map((entities) => entities[0])
            )
        );

        if (!temporaryZoneValue || !mapEntity) {
            return;
        }

        if (this.temporaryZonesComponent.temporaryZoneControl.invalid) {
            this.temporaryZonesComponent.temporaryZoneControl.markAllAsTouched();

            return;
        }

        const selectedZoneId = this.localStore.selectSnapshotByKey("selectedZoneId");

        this.store
            .dispatch(
                new TemporaryZonesActions.AddTemporaryZone(temporaryZoneValue, mapEntity, selectedZoneId ? [selectedZoneId] : undefined)
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.addZoneError);

                if (error) {
                    if (error.type === TemporaryZoneErrorType.AreaOutsideDtm) {
                        this.toastr.error(
                            this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.areaOutsideDtmErrorMessage")
                        );
                    } else {
                        this.toastr.error(
                            this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.addZoneErrorMessage")
                        );
                    }

                    return;
                }

                this.toastr.success(
                    this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.addZoneSuccessMessage")
                );
                this.displayActionBar(false);
                this.clearTemporaryZoneStates();
            });
    }

    protected getDraftDetails(draftId: string): void {
        const selectedZoneId = this.localStore.selectSnapshotByKey("selectedZoneId");

        if (selectedZoneId === draftId) {
            this.store.dispatch(TemporaryZonesActions.ClearSelectedZoneArea);
            this.localStore.patchState({ selectedZoneId: undefined });

            return;
        }

        this.localStore.patchState({ selectedZoneId: draftId });

        this.store
            .dispatch(new TemporaryZonesActions.GetDraftDetails(draftId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.getDraftDetailsError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.getDraftDetailsErrorMessage")
                    );
                }
            });
    }

    public getZoneDetails(zoneId: string): void {
        const selectedZoneId = this.localStore.selectSnapshotByKey("selectedZoneId");

        if (selectedZoneId === zoneId) {
            this.store.dispatch(TemporaryZonesActions.ClearSelectedZoneArea);
            this.localStore.patchState({ selectedZoneId: undefined });

            return;
        }

        this.localStore.patchState({ selectedZoneId: zoneId });

        this.store
            .dispatch(new TemporaryZonesActions.GetZoneDetails(zoneId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.activeZoneDetailsError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate(
                            "dtmSupOperationalSituation.operationalSituationContainer.getActiveZoneDetailsErrorMessage"
                        )
                    );
                }
            });
    }

    protected closeAlert(payloadData: AlertPayloadData): void {
        this.store
            .dispatch(new OperationalSituationActions.CloseMissionAlert(payloadData))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationalSituationState.dismissAlertError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.rejectMissionErrorMessage")
                    );
                }
            });
    }

    protected closeGlobalAlarm(alarm: Alarm): void {
        this.store.dispatch(new GlobalAlarmActions.DeleteAlarm(alarm.id));
    }

    protected displayActionBar(value: boolean): void {
        this.localStore.patchState({ isActionButtonsPanelVisible: value, selectedZoneId: undefined });
        this.store.dispatch(TemporaryZonesActions.ClearSelectedZoneArea);
    }

    protected openConfirmationDialog(): void {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
            data: {
                titleText: this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.zoneDialogTitleText"),
                confirmationText: this.transloco.translate(
                    "dtmSupOperationalSituation.operationalSituationContainer.zoneDialogConfirmationText"
                ),
                declineButtonLabel: this.transloco.translate(
                    "dtmSupOperationalSituation.operationalSituationContainer.zoneDialogDeclineButtonLabel"
                ),
                confirmButtonLabel: this.transloco.translate(
                    "dtmSupOperationalSituation.operationalSituationContainer.zoneDialogConfirmButtonLabel"
                ),
            },
        });

        dialogRef
            .afterClosed()
            .pipe(filter(FunctionUtils.isTruthy), untilDestroyed(this))
            .subscribe(() => {
                this.displayActionBar(false);
                this.clearTemporaryZoneStates();
            });
    }

    protected tryRejectMission(missionId: string, listType: MissionListType): void {
        const dialogRef = this.matDialog.open(RejectMissionDialogComponent, {
            data: {
                isReasonProcessing$: this.isProcessing$,
            },
            disableClose: true,
        });

        dialogRef.componentInstance.setInformation$
            .pipe(
                switchMap((information: string) =>
                    this.store.dispatch(new OperationalSituationActions.RejectMission(missionId, listType, information))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationalSituationState.rejectMissionError);
                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.rejectMissionErrorMessage")
                    );

                    return;
                }

                this.toastr.success(
                    this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.rejectMissionSuccessMessage")
                );
                dialogRef.close();
            });
    }

    protected tryChangeMissionTime(mission: ProceedingMission): void {
        const currentDtmName = this.localStore.selectSnapshotByKey("currentDtmName");

        if (!currentDtmName) {
            return;
        }

        const dialogRef = this.matDialog.open(MissionTimeChangeDialogComponent, {
            data: {
                isProcessing: this.isProcessing$,
                startTime: mission.flightStartAtMin,
                finishTime: mission.flightFinishAtMax,
                missionName: mission.missionName,
            },
            disableClose: true,
        });

        dialogRef.componentInstance.setOverrideForm$
            .pipe(
                switchMap((formValue) =>
                    this.store.dispatch(new OperationalSituationActions.OverrideMissionTime(mission.missionId, formValue, currentDtmName))
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationalSituationState.overrideMissionError);
                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.overrideMissionErrorMessage")
                    );

                    return;
                }

                this.toastr.success(
                    this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.overrideMissionSuccessMessage")
                );
                dialogRef.close();
            });
    }

    private getZones(dtmName: DtmName): void {
        this.getActiveZones(dtmName);
        this.getTemporaryZones(dtmName);
        this.getArchivedZones(dtmName);
    }

    private finishZone(zoneId: string): void {
        this.store
            .dispatch(new TemporaryZonesActions.FinishZone(zoneId))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.finishZoneError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.finishZoneErrorMessage")
                    );
                }
            });
    }

    private getTemporaryZones(dtmName: DtmName): void {
        this.store
            .dispatch(new TemporaryZonesActions.GetDraftZones(dtmName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.getDraftZonesError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.getDraftZonesErrorMessage")
                    );
                }
            });
    }

    private getActiveZones(dtmName: DtmName): void {
        this.store
            .dispatch(new TemporaryZonesActions.GetActiveZones(dtmName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.activeZoneListError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.getActiveZonesErrorMessage")
                    );
                }
            });
    }

    private getArchivedZones(dtmName: DtmName): void {
        this.store
            .dispatch(new TemporaryZonesActions.GetArchiveZones(dtmName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(TemporaryZonesState.archiveZoneListError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.getArchivedZonesErrorMessage")
                    );
                }
            });
    }

    private managePanelsVisibility(): void {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(true));
        this.localStore.patchState({ isOperationalSituationPanelFolded: false });
    }

    private getMissionAlerts(dtmName: DtmName): void {
        this.store
            .dispatch(new OperationalSituationActions.GetMissionAlerts(dtmName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationalSituationState.alertListError);
                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.alertListErrorMessage")
                    );
                }
            });
    }

    private getMissions(dtmName: DtmName): void {
        this.activeViolations.clear();

        this.store
            .dispatch(new OperationalSituationActions.GetMissions(dtmName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationalSituationState.missionListError);
                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.missionListErrorMessage")
                    );
                }
            });
    }

    private getCheckins(dtmName: DtmName): void {
        this.store
            .dispatch(new OperationalSituationActions.GetCheckins(dtmName))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationalSituationState.missionListError);
                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.checkinsListErrorMessage")
                    );
                }
            });
    }

    private watchTacticalMissions(dtmName: DtmName): void {
        const currentDtmName = this.localStore.selectSnapshotByKey("currentDtmName");

        if (dtmName === currentDtmName) {
            return;
        }

        this.store.dispatch([
            OperationalSituationActions.StopTacticalMissionsWatch,
            new OperationalSituationActions.TacticalMissionsWatch(dtmName),
            OperationalSituationActions.StopSectionDeactivationEventWatch,
            OperationalSituationActions.ClearMapEntities,
        ]);

        this.localStore.patchState({ currentDtmName: dtmName });
    }

    protected handleVisibleAreaChange(area: BBox): void {
        this.store.dispatch(new OperationalSituationActions.StartFlightPositionsUpdateWatch(area));
    }

    protected togglePanel(): void {
        this.localStore.patchState(({ isPanelFolded }) => ({ isPanelFolded: !isPanelFolded }));
    }

    protected selectMission(mission: ProceedingMission): void {
        const currentDtmName = this.localStore.selectSnapshotByKey("currentDtmName");

        if (!currentDtmName) {
            return;
        }

        this.localStore.patchState({ selectedTrackerId: mission.trackerId, selectedMissionData: mission });

        this.store
            .dispatch([
                new OperationalSituationActions.GetSelectedMissionDetails(mission.missionId, mission.id),
                new OperationalSituationActions.GetMissionPlanData(mission.id),
                new OperationalSituationActions.StartSectionDeactivationEventWatch(currentDtmName),
            ])
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationalSituationState.missionDetailsError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.getMissionDataErrorMessage")
                    );
                }
            });
    }

    protected selectMissionByTrackerId(trackerIdentifier: string | undefined): void {
        const currentSelectedTrackerId = this.localStore.selectSnapshotByKey("selectedTrackerId");
        if (currentSelectedTrackerId === trackerIdentifier) {
            return;
        }
        const mission = [
            ...(this.store.selectSnapshot(OperationalSituationState.proceedingMissions) ?? []),
            ...(this.store.selectSnapshot(OperationalSituationState.incomingMissions) ?? []),
        ]?.find(({ trackerId }) => trackerId === trackerIdentifier);

        if (!mission) {
            return;
        }

        this.selectMission(mission);
    }

    protected selectZone(zone: AirspaceElement) {
        if (this.store.selectSnapshot(GeoZonesState.selectedZoneId) === zone?.id) {
            this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(undefined));

            return;
        }

        this.cameraHelperService.flyToGeoJSON(zone.geometry);
        this.store.dispatch(new GeoZonesActions.SetSelectedZoneId(zone?.id));
    }

    protected selectCheckin(checkin: Checkin): void {
        this.store.dispatch(new OperationalSituationActions.UpdateSelectedCheckin(checkin));
    }

    protected zoomToRoute(selectedRouteId?: string): void {
        let mission;
        let route;

        if (selectedRouteId) {
            mission = this.store
                .selectSnapshot(OperationalSituationState.currentMissionsData)
                ?.find(({ route: { routeId } }) => routeId === selectedRouteId);
        }

        if (!mission?.route.sections) {
            route = this.store.selectSnapshot(OperationalSituationState.selectedMissionData);
            selectedRouteId = route?.route.routeId;
        }

        if (!selectedRouteId || !(mission || route)) {
            return;
        }

        const routeData: RouteData<Mission> = {
            route: {
                routeId: selectedRouteId,
                sections: mission?.route.sections ?? route?.route.sections ?? [],
                isPathBased: !!mission?.isPathBased,
            },
        };

        this.zoomToRoute$.next(routeData);
    }

    protected zoomToCheckin(area: GeoJSON): void {
        this.cameraHelperService.flyToGeoJSON(area);
    }

    protected processMapActionChange(action: MapActionWithPayload) {
        this.operationalSituationMapService.processMapActionChange(action, {
            radius: this.cylinderEditorRadiusLabelProvider.bind(this),
        });
    }

    protected enrichFlightPositionUpdates(identifier: string): void {
        this.store.dispatch(new FlightTrackingActions.EnrichFlightPositionUpdates(identifier));
    }

    protected areMissionsManagementPermissionsAvailable(permissions: WorkspacePermission[] | undefined): boolean {
        // TODO:DTM-5282  remove this condition after DTM supervisor will be removed
        if (!permissions) {
            return true;
        }

        return permissions.includes(WorkspacePermission.TacticalMissionManagement);
    }

    private updateTrajectoriesOnFlightUpdates(): void {
        this.flights$
            .pipe(
                map((flight) => {
                    const currentMissionsData = this.store.selectSnapshot(OperationalSituationState.currentMissionsData);

                    const missionData = currentMissionsData.find(({ uav: { trackersIdentifiers } }) =>
                        trackersIdentifiers.find((trackerIdentifier) => trackerIdentifier === flight.trackerIdentifier)
                    );

                    return missionData?.status === MissionStatus.Started ? { flight, missionData } : undefined;
                }),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe(({ flight, missionData: { missionId } }) => {
                if (!flight.latitude || !flight.longitude) {
                    return;
                }

                const trajectoriesMap = this.localStore.selectSnapshotByKey("trajectories");
                const trajectories = ObjectUtils.cloneDeep(trajectoriesMap?.get(missionId));

                const activeTrajectory = trajectories?.find((trajectory) => trajectory.isActive);

                const trajectoryPoint: TrajectoryPosition = {
                    latitude: flight.latitude,
                    longitude: flight.longitude,
                };
                if (activeTrajectory) {
                    activeTrajectory.positions.push(trajectoryPoint);
                } else {
                    trajectories?.push({
                        isActive: true,
                        positions: [trajectoryPoint],
                        trackerIdentifier: flight.trackerIdentifier,
                    });
                }

                const update = new Map(trajectoriesMap).set(missionId, trajectories ?? []);

                this.localStore.patchState({ trajectories: update });
            });
    }

    private getTopPriorityViolation(violations: Map<string, Set<Violation | undefined>>): Map<string, Violation | undefined> | undefined {
        if (violations.size === 0) {
            return;
        }

        const mappedViolations: Map<string, Violation | undefined> = new Map();
        violations.forEach((value, key) => {
            mappedViolations.set(
                key,
                VIOLATION_PRIORITY.find((violation) => value.has(violation))
            );
        });

        return mappedViolations;
    }

    private watchForViolationsUpdates(): void {
        this.flightViolationUpdaterService.flightViolationUpdate$
            .pipe(
                map((update) => this.updateActiveViolations(update)),
                map(this.getTopPriorityViolation),
                untilDestroyed(this)
            )
            .subscribe((violations) => {
                this.localStore.patchState({ violations });
            });
    }

    private updateActiveViolations({ type, payload }: ViolationNotification): Map<string, Set<Violation>> {
        const violationSet = this.activeViolations.get(payload.trackerIdentifier) ?? new Set();

        if (type === FlightViolationEvent.FlightViolationOccurredEvent) {
            violationSet.add(payload.violation);
        }

        if (type === FlightViolationEvent.FlightViolationCanceledEvent) {
            violationSet?.delete(payload.violation);
        }

        this.activeViolations.set(payload.trackerIdentifier, violationSet);

        return this.activeViolations;
    }

    private cylinderEditorRadiusLabelProvider(radius: number): string | undefined {
        return this.translationHelper.tryTranslate("dtmSupOperationalSituation.operationalSituation.radiusLabel", radius.toString(), {
            radius: radius.toFixed(0),
        });
    }

    private async selectOperationalSituation() {
        const activatedQueryParams = this.activatedRoute.snapshot.queryParams;
        const availableDtmNames = await firstValueFrom(
            this.store.select(MissionState.dtmAreas).pipe(
                first(FunctionUtils.isTruthy),
                map((areas) => areas.map((area) => area.name))
            )
        );

        if (
            (!activatedQueryParams.dtmName || !availableDtmNames.includes(activatedQueryParams.dtmName)) &&
            availableDtmNames.length === 1
        ) {
            this.setupDtmDetails(availableDtmNames[0], true);

            return true;
        }

        if (!activatedQueryParams.dtmName || !availableDtmNames.includes(activatedQueryParams.dtmName)) {
            this.router.navigate([], {
                queryParams: { dtmName: null },
                replaceUrl: true,
                queryParamsHandling: "merge",
            });

            return false;
        }

        this.setupDtmDetails(activatedQueryParams.dtmName);

        return true;
    }

    private clearTemporaryZoneStates(): void {
        this.temporaryZonesComponent.temporaryZoneControl.reset();
        this.operationalSituationMapService.clearMap();
        this.store.dispatch(TemporaryZonesActions.ClearElevation);
    }

    private watchEditorContent(): void {
        this.operationalSituationMapService.editorContent$
            .pipe(
                map((entities) => entities[0]),
                tap((entity) => {
                    if (!entity) {
                        this.store.dispatch(TemporaryZonesActions.ClearElevation);
                    }
                }),
                filter(FunctionUtils.isTruthy),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((entity) => this.store.dispatch(new TemporaryZonesActions.GetElevation(entity))),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private watchDispatchedActions(): void {
        this.actions
            .pipe(
                ofActionDispatched(OperationalSituationActions.GetMissionPlanAnalysis, OperationalSituationActions.SearchAirspaceElements),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(OperationalSituationState.missionAnalysisDataError);

                if (error) {
                    this.toastr.error(
                        this.transloco.translate("dtmSupOperationalSituation.operationalSituationContainer.getMissionDataErrorMessage")
                    );
                }
            });
    }

    private watchFlightTrackingErrors(): void {
        this.store
            .select(FlightTrackingState.getError)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((error) => {
                switch (error.type) {
                    case FlightTrackingErrorType.CannotGetHeightInformation:
                        this.toastr.error(this.transloco.translate("dtmSupOperationalSituation.cannotGetHeightInformation"));
                        break;

                    default:
                        break;
                }
            });
    }

    private prepareMissionStream() {
        return this.proceedingMissions$.pipe(
            combineLatestWith(this.incomingMissions$, this.finishedMissions$),
            map(([proceedingMissions, incomingMissions, finishedMissions]) => ({ proceedingMissions, incomingMissions, finishedMissions })),
            map((missions) => ({
                proceedingMissions: this.updateSupMissionIdentifier(missions.proceedingMissions),
                incomingMissions: this.updateSupMissionIdentifier(missions.incomingMissions, missions.proceedingMissions?.length),
                finishedMissions: this.updateSupMissionIdentifier(
                    missions.finishedMissions,
                    missions.proceedingMissions?.length,
                    missions.incomingMissions?.length
                ),
            }))
        );
    }

    private updateSupMissionIdentifier(missions: ProceedingMission[] | undefined, ...startPositions: (number | undefined)[]) {
        const startPosition = startPositions.reduce<number>((result, index) => result + (index ?? 0), 1) ?? 1;

        return missions?.map((mission, index) => ({
            ...mission,
            supMissionIdentifier: this.transloco.translate(MISSION_NAME_TRANSLATION_KEY, {
                value: this.decimalPipe.transform(index + startPosition, "2."),
            }),
        }));
    }
}
