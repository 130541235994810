import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { supPermissionGuard } from "../shared/guards/sup-permission.guard";
import { WorkspacePermission } from "../sup-user/index";
import { NetworkMonitoringContainerComponent } from "./components/network-monitoring-container/network-monitoring-container.component";
import { NetworkMonitoringResolver } from "./services/network-monitoring.resolver";

const routes: Routes = [
    {
        path: "network-monitoring",
        canActivate: [DtmRoleGuard, supPermissionGuard([WorkspacePermission.Monitoring])],
        component: NetworkMonitoringContainerComponent,
        resolve: {
            installations: NetworkMonitoringResolver,
        },
        data: {
            titleTranslationKey: "dtmSupNetworkMonitoring.routeTitles.networkMonitoring",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class NetworkMonitoringRoutingModule {}
