import { InjectionToken } from "@angular/core";

export interface OperationalSituationEndpoints {
    getDtmAlerts: string;
    getMissions: string;
    getMission: string;
    getMissionDetails: string;
    tacticalMissionsWatch: string;
    rejectMission: string;
    dismissAlert: string;
    wsFlightControlTopicName: string;
    overrideMissionTime: string;
    getCheckins: string;
    manageTemporaryZones: string;
    manageDraftZones: string;
    getElevation: string;
    publishZone: string;
    getZones: string;
    getZoneDetails: string;
    getMissionPlanVerification: string;
    missionManagement: string;
    finishZone: string;
}

export const OPERATIONAL_SITUATION_ENDPOINTS = new InjectionToken<OperationalSituationEndpoints>("Operational situation endpoints");
