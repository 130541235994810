import { Injectable } from "@angular/core";
import { GeoJSON, Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, finalize, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { TemporaryZoneError } from "../models/operational.situation.models";
import {
    BasicTemporaryZone,
    Elevation,
    TemporaryZone,
    TemporaryZoneTab,
    TemporaryZoneType,
    Zone,
    ZoneFiltersData,
} from "../models/temporary-zones.models";
import { OperationalSituationApiService } from "../services/operational-situation-api.service";
import { DEFAULT_ARCHIVE_ZONES_LIST_SIZE } from "../services/operational-situation.converters";
import { OperationalSituationState } from "./operational-situation.state";
import { TemporaryZonesActions } from "./temporary-zones.actions";

interface TemporaryZonesStateModel {
    isProcessing: boolean;
    zoneList: Zone[] | undefined;
    addZoneError: TemporaryZoneError | undefined;
    getDraftZonesError: TemporaryZoneError | undefined;
    getDraftDetailsError: TemporaryZoneError | undefined;
    deleteDraftError: TemporaryZoneError | undefined;
    publishZoneError: TemporaryZoneError | undefined;
    activeZoneListError: TemporaryZoneError | undefined;
    activeZoneDetailsError: TemporaryZoneError | undefined;
    finishZoneError: TemporaryZoneError | undefined;
    draftZoneList: BasicTemporaryZone[] | undefined;
    activeZoneList: BasicTemporaryZone[] | undefined;
    draftDetails: TemporaryZone | undefined;
    selectedZoneArea: GeoJSON | undefined;
    elevation: Elevation | undefined;
    isElevationProcessing: boolean;
    selectedTab: TemporaryZoneTab;
    archiveZones: BasicTemporaryZone[] | undefined;
    zoneDetails: TemporaryZone | undefined;
    archiveZonePages: Page | undefined;
    loadArchiveZoneError: TemporaryZoneError | undefined;
    archiveZoneListError: TemporaryZoneError | undefined;
    isArchivedZonesFullyLoaded: boolean;
    zoneFilters: ZoneFiltersData | undefined;
    draftFilters: ZoneFiltersData | undefined;
    archivedFilters: ZoneFiltersData | undefined;
    cloneZoneData: TemporaryZone | undefined;
}

const defaultState: TemporaryZonesStateModel = {
    isProcessing: false,
    zoneList: undefined,
    addZoneError: undefined,
    getDraftZonesError: undefined,
    getDraftDetailsError: undefined,
    deleteDraftError: undefined,
    activeZoneListError: undefined,
    activeZoneDetailsError: undefined,
    finishZoneError: undefined,
    publishZoneError: undefined,
    draftZoneList: undefined,
    draftDetails: undefined,
    zoneDetails: undefined,
    selectedZoneArea: undefined,
    elevation: undefined,
    activeZoneList: undefined,
    archiveZones: undefined,
    archiveZonePages: undefined,
    isElevationProcessing: false,
    selectedTab: TemporaryZoneTab.CurrentlyValid,
    isArchivedZonesFullyLoaded: false,
    loadArchiveZoneError: undefined,
    archiveZoneListError: undefined,
    zoneFilters: undefined,
    draftFilters: undefined,
    archivedFilters: undefined,
    cloneZoneData: undefined,
};

@State<TemporaryZonesStateModel>({
    name: "temporaryZones",
    defaults: defaultState,
})
@Injectable()
export class TemporaryZonesState {
    constructor(private readonly operationalSituationApi: OperationalSituationApiService, private readonly store: Store) {}

    @Selector()
    public static addZoneError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.addZoneError;
    }

    @Selector()
    public static getDraftDetailsError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.getDraftDetailsError;
    }

    @Selector()
    public static getDraftZonesError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.getDraftZonesError;
    }

    @Selector()
    public static deleteDraftError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.deleteDraftError;
    }

    @Selector()
    public static publishZoneError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.publishZoneError;
    }

    @Selector()
    public static finishZoneError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.finishZoneError;
    }

    @Selector()
    public static draftZoneList(state: TemporaryZonesStateModel): BasicTemporaryZone[] | undefined {
        return state.draftZoneList;
    }

    @Selector()
    public static archiveZoneList(state: TemporaryZonesStateModel): BasicTemporaryZone[] | undefined {
        return state.archiveZones;
    }

    @Selector()
    public static activeZoneListError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.activeZoneListError;
    }

    @Selector()
    public static archiveZoneListError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.archiveZoneListError;
    }

    @Selector()
    public static loadArchiveZoneError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.loadArchiveZoneError;
    }

    @Selector()
    public static activeZoneList(state: TemporaryZonesStateModel): BasicTemporaryZone[] | undefined {
        return state.activeZoneList;
    }

    @Selector()
    public static activeZoneDetailsError(state: TemporaryZonesStateModel): TemporaryZoneError | undefined {
        return state.activeZoneDetailsError;
    }

    @Selector()
    public static draftDetails(state: TemporaryZonesStateModel): TemporaryZone | undefined {
        return state.draftDetails;
    }

    @Selector()
    public static zoneDetails(state: TemporaryZonesStateModel): TemporaryZone | undefined {
        return state.zoneDetails;
    }

    @Selector()
    public static isProcessing(state: TemporaryZonesStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static isArchivedZonesFullyLoaded(state: TemporaryZonesStateModel): boolean {
        return state.isArchivedZonesFullyLoaded;
    }

    @Selector()
    public static elevation(state: TemporaryZonesStateModel): Elevation | undefined {
        return state.elevation;
    }

    @Selector()
    public static isElevationProcessing(state: TemporaryZonesStateModel): boolean {
        return state.isElevationProcessing;
    }

    @Selector()
    public static selectedTab(state: TemporaryZonesStateModel): number {
        return state.selectedTab;
    }

    @Selector()
    public static selectedZoneArea(state: TemporaryZonesStateModel): GeoJSON | undefined {
        return state.selectedZoneArea;
    }

    @Selector()
    public static cloneZoneData(state: TemporaryZonesStateModel): TemporaryZone | undefined {
        return state.cloneZoneData;
    }

    @Action(TemporaryZonesActions.AddTemporaryZone)
    public addTemporaryZone(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.AddTemporaryZone) {
        context.patchState({ addZoneError: undefined, isProcessing: true });

        return this.operationalSituationApi.addTemporaryZone(action.zoneData, action.mapEntity).pipe(
            tap(() => {
                const selectedDtmName = this.store.selectSnapshot(OperationalSituationState.selectedDtmName);

                if (!selectedDtmName) {
                    return;
                }

                context.patchState({ selectedTab: TemporaryZoneTab.Drafts });
                context.dispatch(new TemporaryZonesActions.GetDraftZones(selectedDtmName));
            }),
            catchError((addZoneError) => {
                context.patchState({ addZoneError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.GetDraftZones)
    public getTemporaryZones(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.GetDraftZones) {
        context.patchState({ getDraftZonesError: undefined, isProcessing: true });

        const draftZoneFilters = context.getState().draftFilters;

        return this.operationalSituationApi.getTemporaryZones(action.dtmName, draftZoneFilters).pipe(
            tap((draftZoneList: BasicTemporaryZone[]) => {
                context.patchState({ draftZoneList });
            }),
            catchError((getDraftZonesError) => {
                context.patchState({ getDraftZonesError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.FinishZone)
    public finishZone(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.FinishZone) {
        context.patchState({ isProcessing: true, finishZoneError: undefined });

        return this.operationalSituationApi.finishZone(action.zoneId).pipe(
            tap(() => {
                context.patchState({ selectedTab: TemporaryZoneTab.Archival });
            }),
            catchError((finishZoneError) => {
                context.patchState({ finishZoneError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.GetDraftDetails)
    public getDraftDetails(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.GetDraftDetails) {
        context.patchState({
            isProcessing: true,
            getDraftDetailsError: undefined,
            draftDetails: undefined,
            selectedZoneArea: undefined,
        });

        return this.operationalSituationApi.getDraftDetails(action.draftId).pipe(
            tap((draftDetails) => {
                context.patchState({ draftDetails, selectedZoneArea: draftDetails.coordinates });
            }),
            catchError((getDraftDetailsError) => {
                context.patchState({ getDraftDetailsError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.DeleteDraft)
    public deleteDraft(context: StateContext<TemporaryZonesStateModel>, { draftId }: TemporaryZonesActions.DeleteDraft) {
        context.patchState({ isProcessing: true, deleteDraftError: undefined });

        return this.operationalSituationApi.deleteDraft(draftId).pipe(
            tap(() => {
                const selectedDtmName = this.store.selectSnapshot(OperationalSituationState.selectedDtmName);

                if (!selectedDtmName) {
                    return;
                }

                context.dispatch(new TemporaryZonesActions.GetDraftZones(selectedDtmName));
            }),
            catchError((deleteDraftError) => {
                context.patchState({ deleteDraftError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.PublishZone)
    public publishZone(context: StateContext<TemporaryZonesStateModel>, { draftId }: TemporaryZonesActions.DeleteDraft) {
        context.patchState({ isProcessing: true, publishZoneError: undefined });

        return this.operationalSituationApi.publishZone(draftId).pipe(
            tap(() => {
                const selectedDtmName = this.store.selectSnapshot(OperationalSituationState.selectedDtmName);

                if (!selectedDtmName) {
                    return;
                }

                context.patchState({ selectedTab: TemporaryZoneTab.CurrentlyValid });
                context.dispatch(new TemporaryZonesActions.GetDraftZones(selectedDtmName));
            }),
            catchError((publishZoneError) => {
                context.patchState({ publishZoneError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.GetElevation)
    public getElevation(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.GetElevation) {
        context.patchState({ isElevationProcessing: true });

        return this.operationalSituationApi.getElevation(action.mapEntity).pipe(
            tap((elevation) => context.patchState({ elevation: elevation })),
            finalize(() => context.patchState({ isElevationProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.ClearElevation)
    public clearElevation(context: StateContext<TemporaryZonesStateModel>) {
        context.patchState({ elevation: undefined });
    }

    @Action(TemporaryZonesActions.ClearSelectedZoneArea)
    public clearSelectedZoneArea(context: StateContext<TemporaryZonesStateModel>) {
        context.patchState({ selectedZoneArea: undefined });
    }

    @Action(TemporaryZonesActions.GetActiveZones)
    public getActiveZones(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.GetActiveZones) {
        context.patchState({ isProcessing: true, activeZoneListError: undefined });

        const zoneFilters = context.getState().zoneFilters;

        return this.operationalSituationApi.getActiveZones(action.dtmName, zoneFilters).pipe(
            tap((activeZoneList) => context.patchState({ activeZoneList })),
            catchError((activeZoneListError) => {
                context.patchState({ activeZoneListError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.GetZoneDetails)
    public getZoneDetails(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.GetZoneDetails) {
        context.patchState({
            isProcessing: true,
            zoneDetails: undefined,
            selectedZoneArea: undefined,
            activeZoneDetailsError: undefined,
        });

        return this.operationalSituationApi.getActiveZoneDetails(action.zoneId).pipe(
            tap((zoneDetails) => context.patchState({ zoneDetails, selectedZoneArea: zoneDetails.coordinates })),
            catchError((activeZoneDetailsError) => {
                context.patchState({ activeZoneDetailsError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.GetArchiveZones)
    public getArchiveZones(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.GetArchiveZones) {
        context.patchState({ isProcessing: true, isArchivedZonesFullyLoaded: false, archiveZoneListError: undefined });

        const archivedZoneFilters = context.getState().archivedFilters;

        return this.operationalSituationApi.getArchiveZones(action.dtmName, undefined, archivedZoneFilters).pipe(
            tap((response) => {
                context.patchState({
                    archiveZones: response.zoneList,
                    archiveZonePages: response.pageable,
                    isArchivedZonesFullyLoaded: response.pageable.totalElements <= DEFAULT_ARCHIVE_ZONES_LIST_SIZE,
                });
            }),
            catchError((archiveZoneListError) => {
                context.patchState({ archiveZoneListError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.LoadMoreArchivedZones)
    public loadMoreArchived(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.LoadMoreArchivedZones) {
        context.patchState({ isProcessing: true, loadArchiveZoneError: undefined });

        const { archiveZones, archiveZonePages } = context.getState();

        if (!archiveZonePages) {
            return;
        }

        return this.operationalSituationApi.getArchiveZones(action.dtmName, archiveZonePages.pageNumber + 1).pipe(
            tap(({ zoneList, pageable }) => {
                if (!zoneList.length) {
                    context.patchState({ isArchivedZonesFullyLoaded: true });

                    return;
                }

                context.patchState({
                    archiveZones: [...(archiveZones ?? []), ...zoneList],
                    archiveZonePages: pageable,
                });
            }),
            catchError((loadArchiveZoneError) => {
                context.patchState({ loadArchiveZoneError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(TemporaryZonesActions.UpdateZoneFiltersData)
    public updateZoneFiltersData(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.UpdateZoneFiltersData) {
        switch (action.zoneType) {
            case TemporaryZoneType.Zone:
                context.patchState({ zoneFilters: action.zoneFiltersData });
                break;
            case TemporaryZoneType.Draft:
                context.patchState({ draftFilters: action.zoneFiltersData });
                break;
            case TemporaryZoneType.Archive:
                context.patchState({ archivedFilters: action.zoneFiltersData });
                break;
        }
    }

    @Action(TemporaryZonesActions.ClonePublishedZone)
    public clonePublishedZone(context: StateContext<TemporaryZonesStateModel>, action: TemporaryZonesActions.ClonePublishedZone) {
        return this.operationalSituationApi.clonePublishedZone(action.zoneId).pipe(
            tap((data) => {
                context.patchState({
                    cloneZoneData: data,
                });
            }),
            catchError((activeZoneDetailsError) => {
                context.patchState({ activeZoneDetailsError });

                return EMPTY;
            })
        );
    }
}
