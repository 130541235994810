import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { Alarm } from "../../shared/models/shared-supervisor-client.models";
import { GlobalAlarmApiService } from "../services/global-alarm-api.service";
import { GlobalAlarmActions } from "./global-alarm.actions";

interface AlertsStateModel {
    alarms: Alarm[] | undefined;
}

const defaultState: AlertsStateModel = {
    alarms: undefined,
};

@State<AlertsStateModel>({
    name: "globalAlarm",
    defaults: defaultState,
})
@Injectable()
export class GlobalAlarmState {
    constructor(private readonly alarmApiService: GlobalAlarmApiService) {}

    @Selector()
    public static alarms(state: AlertsStateModel): Alarm[] | undefined {
        return state.alarms;
    }

    public ngxsOnInit(context: StateContext<AlertsStateModel>) {
        context.dispatch(GlobalAlarmActions.GlobalAlarmWatch);
    }

    @Action(GlobalAlarmActions.GlobalAlarmWatch)
    public globalAlarmWatch(context: StateContext<AlertsStateModel>) {
        return this.alarmApiService.startGlobalAlarmsWatch().pipe(
            tap((response) => {
                const alarmState = context.getState().alarms;
                context.patchState({
                    alarms: [response, ...(alarmState ?? [])],
                });
            })
        );
    }

    @Action(GlobalAlarmActions.DeleteAlarm)
    public deleteAlarm(context: StateContext<AlertsStateModel>, action: GlobalAlarmActions.DeleteAlarm) {
        const alarms = context.getState().alarms;

        if (!alarms) {
            return;
        }

        context.patchState({
            alarms: [...alarms.filter((alarm) => alarm.id !== action.alarmId)],
        });
    }
}
