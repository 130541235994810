import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MapEntity } from "@dtm-frontend/shared/map/cesium";
import { GeoJSON } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import {
    BasicTemporaryZone,
    Elevation,
    TemporaryZone,
    TemporaryZoneFormData,
    TemporaryZoneTab,
    TemporaryZoneType,
    ZoneFilters,
} from "../../models/temporary-zones.models";
import { DEFAULT_ARCHIVE_ZONES_LIST_SIZE } from "../../services/operational-situation.converters";

interface TemporaryZonesComponentState {
    isEditMode: boolean;
    isArchivedZonesFullyLoaded: boolean;
    isElevationProcessing: boolean;
    mapEntity: MapEntity | undefined;
    draftZoneList: BasicTemporaryZone[] | undefined;
    activeZoneList: BasicTemporaryZone[] | undefined;
    archiveZoneList: BasicTemporaryZone[] | undefined;
    draftZoneDetails: TemporaryZone | undefined;
    zoneDetails: TemporaryZone | undefined;
    selectedZoneId: string | undefined;
    elevation: Elevation | undefined;
    selectedTab: number;
}

@Component({
    selector: "supervisor-shared-lib-temporary-zones[mapEntity][draftZoneList]",
    templateUrl: "./temporary-zones.component.html",
    styleUrls: ["./temporary-zones.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TemporaryZonesComponent {
    @Input() public set displayForm(value: BooleanInput) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }
    @Input() public set isElevationProcessing(value: BooleanInput) {
        this.localStore.patchState({ isElevationProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isArchivedZonesFullyLoaded(value: BooleanInput) {
        this.localStore.patchState({ isArchivedZonesFullyLoaded: coerceBooleanProperty(value) });
    }
    @Input() public set elevation(value: Elevation | undefined) {
        this.localStore.patchState({ elevation: value });
    }
    @Input() public set mapEntity(value: MapEntity | undefined) {
        this.localStore.patchState({ mapEntity: value });
    }
    @Input() public set draftZoneDetails(value: TemporaryZone | undefined) {
        this.localStore.patchState({ draftZoneDetails: value });
    }
    @Input() public set zoneDetails(value: TemporaryZone | undefined) {
        this.localStore.patchState({ zoneDetails: value });
    }
    @Input() public set draftZoneList(value: BasicTemporaryZone[] | undefined) {
        this.localStore.patchState({ draftZoneList: value });
    }
    @Input() public set archiveZoneList(value: BasicTemporaryZone[] | undefined) {
        this.localStore.patchState({ archiveZoneList: value });
    }
    @Input() public set activeZoneList(value: BasicTemporaryZone[] | undefined) {
        this.localStore.patchState({ activeZoneList: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }
    @Input() public set selectedTab(value: TemporaryZoneTab.CurrentlyValid | undefined) {
        this.localStore.patchState({ selectedTab: value ?? TemporaryZoneTab.CurrentlyValid });
    }
    @Input() public set cloneZoneData(value: TemporaryZone | undefined) {
        if (value) {
            this.temporaryZoneControl.patchValue(this.convertTemporaryZoneToTemporaryZoneFormData(value));
        }
    }

    @Output() public readonly editModeChange = new EventEmitter<void>();
    @Output() public readonly zoneSelect = new EventEmitter<string>();
    @Output() public readonly draftSelect = new EventEmitter<string>();
    @Output() public readonly archiveSelect = new EventEmitter<string>();
    @Output() public readonly draftDelete = new EventEmitter<string>();
    @Output() public readonly zonePublish = new EventEmitter<string>();
    @Output() public readonly zoneZoom = new EventEmitter<GeoJSON>();
    @Output() public readonly tabChange = new EventEmitter<TemporaryZoneTab>();
    @Output() public readonly loadMore = new EventEmitter<void>();
    @Output() public readonly zoneFinish = new EventEmitter<string>();

    @Output() public readonly zonesFiltersChange = new EventEmitter<ZoneFilters>();
    @Output() public readonly zoneClone = new EventEmitter<string>();

    public readonly temporaryZoneControl = new FormControl<TemporaryZoneFormData | null>(null);
    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly isElevationProcessing$ = this.localStore.selectByKey("isElevationProcessing");
    protected readonly isArchivedZonesFullyLoaded$ = this.localStore.selectByKey("isArchivedZonesFullyLoaded");
    protected readonly mapEntity$ = this.localStore.selectByKey("mapEntity");
    protected readonly elevation$ = this.localStore.selectByKey("elevation");
    protected readonly draftZoneDetails$ = this.localStore.selectByKey("draftZoneDetails");
    protected readonly zoneDetails$ = this.localStore.selectByKey("zoneDetails");
    protected readonly draftZoneList$ = this.localStore.selectByKey("draftZoneList");
    protected readonly archiveZoneList$ = this.localStore.selectByKey("archiveZoneList");
    protected readonly activeZoneList$ = this.localStore.selectByKey("activeZoneList");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly selectedTab$ = this.localStore.selectByKey("selectedTab");

    protected readonly TemporaryZoneType = TemporaryZoneType;
    protected readonly DEFAULT_ARCHIVE_ZONES_LIST_SIZE = DEFAULT_ARCHIVE_ZONES_LIST_SIZE;

    constructor(private readonly localStore: LocalComponentStore<TemporaryZonesComponentState>) {
        this.localStore.setState({
            isEditMode: false,
            mapEntity: undefined,
            isArchivedZonesFullyLoaded: false,
            draftZoneList: undefined,
            activeZoneList: undefined,
            selectedZoneId: undefined,
            draftZoneDetails: undefined,
            archiveZoneList: undefined,
            isElevationProcessing: false,
            elevation: undefined,
            zoneDetails: undefined,
            selectedTab: TemporaryZoneTab.CurrentlyValid,
        });
    }

    protected changeEditMode(): void {
        this.localStore.patchState({ isEditMode: true });
        this.editModeChange.emit();
    }

    protected getTemporaryZoneDetails(zoneId: string, areaType: TemporaryZoneType): void {
        const selectedZoneId = this.localStore.selectSnapshotByKey("selectedZoneId");

        if (zoneId === selectedZoneId) {
            this.localStore.patchState({ selectedZoneId: zoneId });

            return;
        }

        switch (areaType) {
            case TemporaryZoneType.Draft:
                this.draftSelect.emit(zoneId);
                break;
            case TemporaryZoneType.Zone:
                this.zoneSelect.emit(zoneId);
                break;
            default:
                return;
        }
    }

    protected convertTemporaryZoneToTemporaryZoneFormData(zone: TemporaryZone): TemporaryZoneFormData {
        let dailyActivationsHours;

        if (zone.dailyActivations) {
            const [startTimeHours, startTimeMinutes] = zone.dailyActivations.startTime.split(":");
            const [endTimeHours, endTimeMinutes] = zone.dailyActivations.endTime.split(":");

            dailyActivationsHours = {
                startTimeHours,
                startTimeMinutes,
                endTimeHours,
                endTimeMinutes,
            };
        }

        return {
            zoneName: zone.zoneName,
            zoneType: zone.zoneType,
            maxAltitude: zone.maxAltitude,
            minAltitude: zone.minAltitude,
            zoneDescription: zone.zoneDescription,
            zoneStartDate: new Date(zone.zoneStartDate),
            zoneEndDate: new Date(zone.zoneEndDate),
            zoneStartTime: new Date(zone.zoneStartDate),
            zoneEndTime: new Date(zone.zoneEndDate),
            phoneNumber: zone.phoneNumber,
            note: zone.note,
            infoZoneType: zone.infoZoneType,
            zoneObjectType: zone.zoneObjectType,
            dailyActivation:
                zone.dailyActivations && dailyActivationsHours
                    ? {
                          startTime: new Date(
                              new Date().setHours(+dailyActivationsHours.startTimeHours, +dailyActivationsHours.startTimeMinutes)
                          ),
                          endTime: new Date(
                              new Date().setHours(+dailyActivationsHours.endTimeHours, +dailyActivationsHours.endTimeMinutes)
                          ),
                      }
                    : undefined,
            isValidityPeriod: !!(zone.weeklyActivations?.length || zone.dailyActivations),
            activationHours: zone.weeklyActivations,
        };
    }
}
