<dtm-ui-expandable-panel
    [hasHeaderSeparator]="false"
    [isDisabled]="!vm.isFormDisplayed"
    [class.disabled]="!vm.isFormDisplayed"
    [isExpanded]="vm.isFormDisplayed || (!!vm.note && vm.note.message !== '')"
    (expandablePanelOpen)="messagePanelOpen.emit()"
    *ngrxLet="{ isFormDisplayed: isFormDisplayed$, note: note$ } as vm"
>
    <div class="header" headerSlot>
        <dtm-ui-icon name="mail-send"></dtm-ui-icon>
        <h2 class="title">
            {{ panelTitle$ | ngrxPush }}
        </h2>
        <button
            type="button"
            class="button-secondary add-button"
            (click)="changeFormVisibility($event)"
            *ngIf="(!vm.isFormDisplayed && !vm.note) || (!vm.isFormDisplayed && !vm.note?.message)"
        >
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "dtmSupPlannedMissions.pilotMessage.addButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-icon add-button" (click)="changeFormVisibility($event)" *ngIf="vm.isFormDisplayed">
            <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
        </button>
        <button
            type="button"
            class="button-icon add-button"
            (click)="changeFormVisibility($event)"
            *ngIf="!vm.isFormDisplayed && vm.note?.message"
        >
            <dtm-ui-icon name="edit"></dtm-ui-icon>
        </button>
    </div>
    <dtm-ui-textarea-field [maxLength]="maxMessageLength$ | ngrxPush" *ngIf="vm.isFormDisplayed">
        <label>{{ (controlLabel$ | ngrxPush) ?? ("dtmSupPlannedMissions.pilotMessage.messageControlLabel" | transloco) }}</label>
        <textarea [formControl]="messageControl" matInput></textarea>
        <div class="field-error" *dtmUiFieldHasError="messageControl; name: 'maxlength'; error as error">
            {{ "dtmSupPlannedMissions.pilotMessage.messageMaxLengthError" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-textarea-field>
    <div class="action" *ngIf="(isSaveButtonDisplayed$ | ngrxPush) && vm.isFormDisplayed">
        <button type="button" class="button-primary" (click)="saveMessage()">
            {{ "dtmSupPlannedMissions.pilotMessage.saveButtonLabel" | transloco }}
        </button>
    </div>
    <dtm-ui-label-value
        *ngIf="!vm.isFormDisplayed && !!vm.note && vm.note.message !== ''"
        [label]="vm.note.modifiedAt | localizeDate"
        [value]="vm.note.message"
    ></dtm-ui-label-value>
</dtm-ui-expandable-panel>
