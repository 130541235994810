import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { firstValueFrom } from "rxjs";
import { SupUserState, WorkspacePermission } from "../../sup-user";

export function supPermissionGuard(routePermissions: WorkspacePermission[]): CanActivateFn {
    return async () => {
        const store = inject(Store);
        const router = inject(Router);
        const context = await firstValueFrom(store.select(SupUserState.selectedWorkspace));

        // TODO:DTM-5282  remove this condition after DTM supervisor will be removed
        if (!context) {
            return true;
        }

        if (routePermissions.find((permission) => context.permissions.includes(permission))) {
            return true;
        }

        router.navigate(["/"]);

        return false;
    };
}
