import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedMissionModule } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { NgxMaskModule } from "ngx-mask";
import { CapabilitiesApiService } from "../shared/services/capabilities-api.service";
import { SoundService } from "../shared/services/sound.service";
import { SharedSupervisorClientModule } from "../shared/shared-supervisor-client.module";
import { MissionFilterComponent } from "./components/mission-filter/mission-filter.component";
import { MissionInfoPanelComponent } from "./components/mission-info-panel/mission-info-panel.component";
import { NearbyMissionsFiltersComponent } from "./components/nearby-missions/components/nearby-missions-filters/nearby-missions-filters.component";
import { NearbyMissionsComponent } from "./components/nearby-missions/nearby-missions.component";
import { PhaseDescriptionComponent } from "./components/phase-description/phase-description.component";
import { PilotRequestComponent } from "./components/pilot-request/pilot-request.component";
import { PlanMessageComponent } from "./components/plan-message/plan-message.component";
import { PlannedMissionContainerComponent } from "./components/planned-mission-container/planned-mission-container.component";
import { PlannedMissionDetailsComponent } from "./components/planned-mission-details/planned-mission-details.component";
import { PriorityDialogComponent } from "./components/priority-dialog/priority-dialog.component";
import { TimeTileComponent } from "./components/time-tile/time-tile.component";
import { PlannedMissionApiService } from "./services/planned-mission-api.service";
import { MissionState } from "./state/mission.state";

@NgModule({
    declarations: [
        PlannedMissionContainerComponent,
        PlannedMissionDetailsComponent,
        MissionInfoPanelComponent,
        MissionFilterComponent,
        NearbyMissionsComponent,
        NearbyMissionsFiltersComponent,
        PhaseDescriptionComponent,
        PriorityDialogComponent,
        TimeTileComponent,
        PlanMessageComponent,
        PilotRequestComponent,
    ],
    imports: [
        CommonModule,
        SharedUiModule,
        LetModule,
        PushModule,
        NgxsModule.forFeature([MissionState]),
        ReactiveFormsModule,
        MatTooltipModule,
        MatTabsModule,
        SharedI18nModule,
        MatChipsModule,
        MatInputModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        NgxMaskModule,
        SharedSupervisorClientModule.forRoot(),
        SharedMissionModule,
    ],
    exports: [
        MissionFilterComponent,
        TimeTileComponent,
        NearbyMissionsComponent,
        NearbyMissionsFiltersComponent,
        MissionInfoPanelComponent,
        PlanMessageComponent,
        PilotRequestComponent,
        PlannedMissionDetailsComponent,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmSupPlannedMissions",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: [PlannedMissionApiService],
            useValue: undefined,
        },
        {
            provide: WebsocketService,
            useValue: undefined,
        },
        {
            provide: SoundService,
            useValue: undefined,
        },
    ],
})
export class PlannedMissionsModule {
    public static forRoot(): ModuleWithProviders<PlannedMissionsModule> {
        return {
            ngModule: PlannedMissionsModule,
            providers: [PlannedMissionApiService, WebsocketService, SoundService],
        };
    }

    public static forTest(): ModuleWithProviders<PlannedMissionsModule> {
        return {
            ngModule: PlannedMissionsModule,
            providers: [
                {
                    provide: PlannedMissionApiService,
                    useValue: null,
                },
                {
                    provide: SoundService,
                    useValue: null,
                },
                {
                    provide: CapabilitiesApiService,
                    useValue: null,
                },
            ],
        };
    }
}
