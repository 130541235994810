export enum MissionsEvents {
    ManualPlanVerificationSubmittedEvent = "ManualPlanVerificationSubmittedEvent",
    MissionAcceptedEvent = "MissionAcceptedEvent",
    MissionActivatedEvent = "MissionActivatedEvent",
    MissionRealizationStartedEvent = "MissionRealizationStartedEvent",
    MissionEndedEvent = "MissionEndedEvent",
    MissionEndedByTimeoutEvent = "MissionEndedByTimeoutEvent",
    MissionCanceledEvent = "MissionCanceledEvent",
    FlightViolationOccurredEvent = "FlightViolationOccurredEvent",
    FlightViolationCanceledEvent = "FlightViolationCanceledEvent",
    SectionDeactivated = "SectionDeactivated",
    SignalViolationOccurred = "SignalViolationOccurredEvent",
    CheckinSubmittedEvent = "CheckinSubmittedEvent",
    CheckinRealizationStartedEvent = "CheckinRealizationStartedEvent",
    CheckinExpiredEvent = "CheckinExpiredEvent",
    CheckinCompletedEvent = "CheckinCompletedEvent",
    MissionRealizationTimeoutEvent = "MissionRealizationTimeoutEvent",
}

export interface Alarm {
    id: string;
    message: string;
    signalViolationType?: SignalViolationType;
}

export enum SignalViolationType {
    ConnectionLost = "CONNECTION_LOST",
    DataLost = "DATA_LOST",
}

export interface CapabilitiesError {
    type: CapabilitiesErrorType;
}

export enum CapabilitiesErrorType {
    CannotGetCapabilities = "CannotGetCapabilities",
}

export enum SupFeatures {
    MissionList = "MISSION_LIST",
}

export interface Capabilities {
    features: SupFeatures[];
}
