import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import {
    MissionContextType,
    MissionDataSimple,
    MissionPlanAnalysisStatus,
    MissionPlanDataAndCapabilities,
    MissionProcessingPhase,
    MissionTimeRange,
    MissionType,
} from "@dtm-frontend/shared/mission";
import { MissionSegmentStatus } from "@dtm-frontend/shared/ui";
import { MissionData } from "@dtm-frontend/shared/ui/tactical";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Observable, combineLatest, map, switchMap } from "rxjs";
import { TacticalMissionUtils } from "../../../shared/utils/tactical-mission-utils";
import { MissionStatus, ProceedingMission } from "../../models/operational.situation.models";

const overrideTimeStatuses: MissionStatus[] = [MissionStatus.Accepted, MissionStatus.MissionReady, MissionStatus.MissionSubmitted];
const NUMBER_OF_ID_CHARACTERS = 5;

interface ComingMissionTileComponentState {
    proceedingMission: ProceedingMission | undefined;
    missionCount: number | undefined;
    isExpanded: boolean;
    isSelected: boolean;
    isZoomAvailable: boolean;
    selectedMission: MissionData | undefined;
    currentMissionPlanData: MissionPlanDataAndCapabilities | undefined;
    zones: AirspaceElementsInfo | undefined;
    currentPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    segmentStatuses: MissionSegmentStatus[];
    isManageable: boolean;
}

@Component({
    selector: "supervisor-shared-lib-proceeding-mission-tile[proceedingMission]",
    templateUrl: "./proceeding-mission-tile.component.html",
    styleUrls: ["./proceeding-mission-tile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class ProceedingMissionTileComponent {
    @Input() public set proceedingMission(value: ProceedingMission | undefined) {
        this.localStore.patchState({ proceedingMission: value });
    }
    @Input() public set missionCount(value: number | undefined) {
        this.localStore.patchState({ missionCount: value });
    }
    @Input() public set isSelected(value: BooleanInput) {
        this.localStore.patchState({ isSelected: coerceBooleanProperty(value) });

        if (!value) {
            this.localStore.patchState({ isExpanded: false });
        }
    }
    @Input() public set isZoomAvailable(value: BooleanInput) {
        this.localStore.patchState({ isZoomAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set selectedMission(value: MissionData | undefined) {
        this.localStore.patchState({ selectedMission: value });
    }
    @Input() public set currentMissionPlanData(value: MissionPlanDataAndCapabilities | undefined) {
        this.localStore.patchState({ currentMissionPlanData: value });
    }
    @Input() public set zones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ zones: value });
    }
    @Input() public set currentPlanAnalysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ currentPlanAnalysisStatus: value });
    }
    @Input() public set segmentStatuses(value: MissionSegmentStatus[] | undefined) {
        this.localStore.patchState({ segmentStatuses: value ?? [] });
    }
    @Input() public set isManageable(value: BooleanInput) {
        this.localStore.patchState({ isManageable: coerceBooleanProperty(value) });
    }

    @Output() public readonly missionRoadZoom = new EventEmitter<string>();
    @Output() public readonly missionReject = new EventEmitter<string>();
    @Output() public readonly missionTimeChange = new EventEmitter<ProceedingMission>();
    @Output() public readonly missionSelect = new EventEmitter<ProceedingMission>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly proceedingMission$ = this.localStore.selectByKey("proceedingMission").pipe(RxjsUtils.filterFalsy());
    protected readonly isActiveMission$ = this.localStore
        .selectByKey("proceedingMission")
        .pipe(map((mission) => mission?.missionStatus === MissionStatus.Activated || mission?.missionStatus === MissionStatus.Started));
    protected readonly missionCount$ = this.localStore.selectByKey("missionCount");
    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly isSelected$ = this.localStore.selectByKey("isSelected");
    protected readonly isZoomAvailable$ = this.localStore.selectByKey("isZoomAvailable");
    protected readonly zones$ = this.localStore.selectByKey("zones");
    protected readonly selectedMission$ = this.localStore.selectByKey("selectedMission");
    protected readonly currentMissionPlanData$ = this.localStore.selectByKey("currentMissionPlanData");
    protected readonly currentPlanAnalysisStatus$ = this.localStore.selectByKey("currentPlanAnalysisStatus");
    protected readonly segmentStatuses$ = this.localStore.selectByKey("segmentStatuses");
    protected readonly isManageable$ = this.localStore.selectByKey("isManageable");
    protected readonly MissionType = MissionType;
    protected readonly MissionStatus = MissionStatus;
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly MissionContextType = MissionContextType;
    protected readonly missionTimeRange$: Observable<MissionTimeRange> = this.localStore.selectByKey("proceedingMission").pipe(
        RxjsUtils.filterFalsy(),
        map((mission) => [TacticalMissionUtils.convertMissionToMissionTimeRange(mission), mission] as const),
        switchMap(([timeRange, mission]) =>
            this.selectedMission$.pipe(
                map((selectedMission) => {
                    if (selectedMission?.missionId === mission.missionId) {
                        return {
                            ...timeRange,
                            plannedStartAt: selectedMission.plannedStartTime?.min,
                            plannedFinishAt: selectedMission.plannedEndTime?.max,
                        };
                    }

                    return timeRange;
                })
            )
        )
    );
    protected readonly missionDataSimple$ = combineLatest([
        this.currentMissionPlanData$.pipe(RxjsUtils.filterFalsy()),
        this.selectedMission$.pipe(RxjsUtils.filterFalsy()),
    ]).pipe(map(([missionPlanData, selectedMission]) => this.prepareMissionDataSimple(missionPlanData, selectedMission)));

    constructor(private readonly localStore: LocalComponentStore<ComingMissionTileComponentState>) {
        this.localStore.setState({
            proceedingMission: undefined,
            missionCount: undefined,
            isExpanded: false,
            isSelected: false,
            isZoomAvailable: false,
            selectedMission: undefined,
            currentMissionPlanData: undefined,
            zones: undefined,
            currentPlanAnalysisStatus: undefined,
            segmentStatuses: [],
            isManageable: false,
        });
    }

    protected expandContent(): void {
        this.localStore.patchState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
    }

    protected isFinishedMission(status: MissionStatus): boolean {
        return status === MissionStatus.Finished || status === MissionStatus.Rejected || status === MissionStatus.Canceled;
    }

    protected isReadyMission(status: MissionStatus): boolean {
        return status === MissionStatus.Accepted || status === MissionStatus.MissionReady || status === MissionStatus.MissionSubmitted;
    }

    protected shouldOverrideButtonBeVisible(mission: ProceedingMission): boolean {
        return overrideTimeStatuses.includes(mission.missionStatus) && mission.missionType === MissionType.VLOS && !mission.isPathBased;
    }

    protected selectMission(mission: ProceedingMission) {
        if (this.localStore.selectSnapshotByKey("isSelected")) {
            return;
        }

        this.missionSelect.emit(mission);
    }

    protected zoomToRoute(event: Event, routeId: string) {
        event.stopPropagation();
        this.missionRoadZoom.emit(routeId);
    }

    protected displayShortId(id: string): string {
        return TacticalMissionUtils.getShortId(id, NUMBER_OF_ID_CHARACTERS);
    }

    private prepareMissionDataSimple(
        { plan, flightPurposes }: MissionPlanDataAndCapabilities,
        { route, operatorName }: MissionData
    ): MissionDataSimple {
        return {
            isRoutePathBased: !!route.isPathBased,
            flightStartAtMin: plan.flightStartAtMin,
            flightStartAtMax: plan.flightStartAtMax,
            flightFinishAtMin: plan.flightFinishAtMin,
            flightFinishAtMax: plan.flightFinishAtMax,
            phase: plan.phase,
            distance: route.estimatedDistance,
            operatorName: operatorName,
            pilotName: plan.capabilities.pilotName,
            uavName:
                plan.capabilities.uavName && plan.capabilities.setupName
                    ? `${plan.capabilities.uavName} (${plan.capabilities.setupName})`
                    : undefined,
            uavSerialNumbers: plan.uav.serialNumbers ?? [],
            trackersIdentifiers: plan.capabilities.trackersIdentifiers ?? [],
            category: plan.category,
            flightPurpose: {
                nameTranslationKey: flightPurposes?.find((purpose) => purpose.id === plan.flightPurpose?.id)?.name ?? "",
                comment: plan.flightPurpose?.comment ?? undefined,
            },
            additionalCrew: plan.capabilities.additionalCrew,
        };
    }
}
