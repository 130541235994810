import { MissionProcessingPhase, MissionType } from "@dtm-frontend/shared/mission";
import { PhoneNumber, TimeRange } from "@dtm-frontend/shared/ui";
import { Checkin as TacticalCheckins } from "@dtm-frontend/shared/ui/tactical";

export { CheckinStatus } from "@dtm-frontend/shared/ui/tactical";

export enum RestrictionType {
    FlightsProhibited = "FlightsProhibited",
    ApprovalRequired = "ApprovalRequired",
}

export enum InfoZone {
    Landings = "LANDINGS",
    PermanentGathering = "PERMANENT_GATHERING",
    TemporaryGathering = "TEMPORARY_GATHERING",
    Obstacles = "OBSTACLES",
    AdsbReceivers = "ADSB_RECEIVERS",
    AdsbCoverMap = "ADSB_COVER_MAP",
    OtherInformation = "OTHER_INFORMATION",
}

export enum MissionStatus {
    Accepted = "ACCEPTED",
    Canceled = "CANCELED",
    Activated = "ACTIVATED",
    Started = "STARTED",
    Finished = "FINISHED",
    MissionReady = "MISSION_READY",
    MissionSubmitted = "MISSION_SUBMITTED",
    MissionAbandoned = "MISSION_ABANDONED",
    Rejected = "REJECTED",
    All = "ALL",
}

export enum OperationType {
    Checkin = "Checkin",
    ProceedingMission = "ProceedingMission",
}

export enum ViolationType {
    UavLeftOwnFlightArea = "UAV_LEFT_OWN_FLIGHT_AREA",
    UavLeftOwnSafetyArea = "UAV_LEFT_OWN_SAFETY_AREA",
    UavOutsideStartingFlightZone = "UAV_OUTSIDE_STARTING_FLIGHT_ZONE",
    UavEnteredForeignSafetyArea = "UAV_ENTERED_FOREIGN_SAFETY_AREA",
}

export enum TemporaryZoneErrorType {
    CannotAddZone = "CannotAddZone",
    CannotGetTemporaryZones = "CannotGetTemporaryZones",
    CannotGetActiveZones = "CannotGetActiveZones",
    CannotGetArchiveZones = "CannotGetArchiveZones",
    CannotLoadArchivedZones = "CannotLoadArchivedZones",
    CannotGetDraftDetails = "CannotGetDraftDetails",
    CannotGetZoneDetails = "CannotGetZoneDetails",
    CannotDeleteDraftZone = "CannotDeleteDraftZone",
    CannotPublishZone = "CannotPublishZone",
    CannotFinishZone = "CannotFinishZone",
    AreaOutsideDtm = "AreaOutsideDtm",
}

export interface TemporaryZoneError {
    type: TemporaryZoneErrorType;
}

export interface OperationalSituationError {
    type: OperationalSituationErrorType;
}

export enum OperationalSituationErrorType {
    Unknown = "Unknown",
    CannotGetAlertList = "CannotGetAlertList",
    CannotGetMissionList = "CannotGetMissionList",
    CannotRejectMission = "CannotRejectMission",
    CannotDismissAlert = "CannotDismissAlert",
    CannotOverrideMission = "CannotOverrideMission",
    CannotGetCheckins = "CannotGetCheckins",
    CannotGetMissionPlanData = "CannotGetMissionPlanData",
    CannotGetAirspaceElements = "CannotGetAirspaceElements",
    CannotGetMissionDetails = "CannotGetMissionDetails",
}

export interface MissionAlert {
    missionId: string;
    name: string;
    violations: Violation[];
}

interface Violation {
    violationType: ViolationType;
    startedAt: Date | undefined;
    finishedAt: Date | undefined;
}

export interface OverrideMissionFormValue {
    startAt: Date;
    finishAt: Date;
    information: string;
}

export interface ProceedingMission {
    id: string;
    missionId: string;
    missionType: MissionType;
    missionName: string;
    missionStatus: MissionStatus;
    uavName: string;
    routeId: string;
    priority: string;
    flightStartAtMax: Date | undefined;
    flightStartAtMin: Date | undefined;
    flightFinishAtMin: Date | undefined;
    flightFinishAtMax: Date | undefined;
    flightStartAt: Date | undefined;
    flightFinishAt: Date | undefined;
    isViolated: boolean;
    isEmergency: boolean;
    phoneNumber: PhoneNumber;
    trackerId?: string;
    isPathBased: boolean;
    operationType: OperationType.ProceedingMission;
    plannedStartTime: TimeRange;
    plannedEndTime: TimeRange;
    phase: MissionProcessingPhase;
}

export interface AlertPayloadData {
    missionId: string;
    alertIndex: number;
}

export enum MissionListType {
    Incoming = "Incoming",
    Proceeding = "Proceeding",
}

export enum GeographicalZone {
    DRAR = "DRA-R",
    DRAP = "DRA-P",
    DRAI = "DRA-I",
    LocalInformation = "LOCAL",
}

export interface Checkin extends TacticalCheckins {
    operationType: OperationType.Checkin;
}
