import { InjectionToken } from "@angular/core";

export interface PlannedMissionEndpoints {
    getPlannedMission: string;
    getMissionRoute: string;
    updateMissionPriority: string;
    missionAcceptance: string;
    missionRejection: string;
    dtmMissionsWatch: string;
    nearbyMissionPlansWatch: string;
    getNearbyMissions: string;
    updateSupervisorNote: string;
    getFile: string;
    missionManagement: string;
    getMissionPlanVerification: string;
}

export const PLANNED_MISSION_ENDPOINTS = new InjectionToken<PlannedMissionEndpoints>("Planned mission endpoints");
