<mat-tab-group
    class="dtm-tabs"
    dynamicHeight
    color="accent"
    disablePagination
    *ngrxLet="{
        filteredIncomingMissions: filteredIncomingMissions$,
        selectedOperationId: selectedOperationId$,
        selectedMission: selectedMission$,
        currentMissionPlanData: currentMissionPlanData$,
        zones: zones$,
        operationList: operationList$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$
    } as vm"
>
    <mat-tab [label]="'dtmSupOperationalSituation.otherMissions.incomingMissionsTabLabel' | transloco">
        <div class="tab-content">
            <div class="header">
                <span class="title">{{ "dtmSupOperationalSituation.otherMissions.incomingMissionsTitle" | transloco }}</span>
                <supervisor-shared-lib-mission-type-filter
                    [defaultFilters]="MISSION_TYPE_FILTERS_DEFAULTS"
                    (filterChanged)="updateIncomingMissionFilters($event)"
                ></supervisor-shared-lib-mission-type-filter>
            </div>
            <supervisor-shared-lib-proceeding-mission-tile
                *ngFor="let mission of vm.filteredIncomingMissions; let index = index"
                [proceedingMission]="mission"
                [missionCount]="index + 1"
                [isSelected]="mission.missionId === vm.selectedOperationId"
                [isZoomAvailable]="mission.missionId === vm.selectedOperationId"
                [currentMissionPlanData]="vm.currentMissionPlanData"
                [zones]="vm.zones"
                [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                [selectedMission]="vm.selectedMission"
                (missionReject)="missionReject.emit($event)"
                (missionSelect)="missionSelected.emit($event)"
                (missionTimeChange)="missionTimeChange.emit($event)"
                (missionRoadZoom)="missionRoadZoom.emit($event)"
                (zoneSelect)="zoneSelect.emit($event)"
            ></supervisor-shared-lib-proceeding-mission-tile>
            <div class="empty-list" *ngIf="!vm.filteredIncomingMissions.length">
                {{ "dtmSupOperationalSituation.otherMissions.emptyListLabel" | transloco }}
            </div>
        </div>
    </mat-tab>
    <mat-tab [label]="'dtmSupOperationalSituation.otherMissions.finishedMissionsTabLabel' | transloco">
        <div class="tab-content">
            <div class="header">
                <span class="title">{{ "dtmSupOperationalSituation.otherMissions.finishedMissionsTitle" | transloco }}</span>
                <supervisor-shared-lib-mission-type-filter
                    shouldDisplayCheckinFilter
                    [defaultFilters]="MISSION_TYPE_FILTERS_DEFAULTS"
                    (filterChanged)="updateFinishedMissionFilters($event)"
                ></supervisor-shared-lib-mission-type-filter>
            </div>
            <ng-container *ngFor="let operation of vm.operationList; let index = index">
                <supervisor-shared-lib-proceeding-mission-tile
                    *ngIf="operation.operationType === OperationType.ProceedingMission"
                    [proceedingMission]="operation"
                    [missionCount]="index + 1"
                    [isSelected]="operation.missionId === vm.selectedOperationId"
                    [isZoomAvailable]="operation.missionId === vm.selectedOperationId"
                    [currentMissionPlanData]="vm.currentMissionPlanData"
                    [zones]="vm.zones"
                    [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                    [selectedMission]="vm.selectedMission"
                    (missionSelect)="missionSelected.emit($event)"
                    (missionRoadZoom)="missionRoadZoom.emit($event)"
                    (zoneSelect)="zoneSelect.emit($event)"
                ></supervisor-shared-lib-proceeding-mission-tile>
                <supervisor-shared-lib-checkin-tile
                    *ngIf="operation.operationType === OperationType.Checkin"
                    [missionCount]="index + 1"
                    [checkin]="operation"
                    [isSelected]="operation.id === vm.selectedOperationId"
                ></supervisor-shared-lib-checkin-tile>
            </ng-container>
            <div class="empty-list" *ngIf="!vm.operationList.length">
                {{ "dtmSupOperationalSituation.otherMissions.emptyListLabel" | transloco }}
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
