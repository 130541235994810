import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanAnalysisStatus, MissionPlanDataAndCapabilities } from "@dtm-frontend/shared/mission";
import { MissionPlanRoute } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { WorkspacePermission } from "../../../sup-user";
import {
    DtmArea,
    Mission,
    MissionFilters,
    MissionProcessingPhase,
    NoteData,
    PhaseFilters,
    PhasePayloadData,
    PlannedMissionFilters,
    PriorityPayloadData,
} from "../../models/mission.models";
import { MissionFilterComponent } from "../mission-filter/mission-filter.component";

interface PlannedMissionDetailsComponentState {
    waitingMissions: Mission[] | undefined;
    acceptedMissions: Mission[] | undefined;
    rejectedMissions: Mission[] | undefined;
    selectedMissionRoute: MissionPlanRoute | undefined;
    selectedTileId: string | undefined;
    isProcessing: boolean;
    isMissionListProcessing: boolean;
    areNewMissionsAvailable: boolean;
    isPlanRouteProcessing: boolean;
    shouldPilotPanelClose: boolean;
    missionDefaultsFilters: MissionFilters | undefined;
    currentMissionPlanData: MissionPlanDataAndCapabilities | undefined;
    zones: AirspaceElementsInfo | undefined;
    currentPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    dtmAreas: DtmArea[];
    workspacePermissions: WorkspacePermission[] | undefined;
    selectedZoneId: string | undefined;
}

const WAITING_SORTING_MAP = {
    "flightStartAtMin,asc": "flightStartAtAsc",
    "submittedAt,asc": "receivedAsc",
    "submittedAt,desc": "receivedDesc",
};

const REST_SORTING_MAP = {
    "flightStartAtMin,asc": "flightStartAtAsc",
    "created,asc": "receivedAsc",
    "created,desc": "receivedDesc",
};

const PHASE_ARRAY: MissionProcessingPhase[] = [
    MissionProcessingPhase.Waiting,
    MissionProcessingPhase.Accepted,
    MissionProcessingPhase.Rejected,
];

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-planned-mission-details[waitingMissions][rejectedMissions][acceptedMissions][isProcessing][zones]",
    templateUrl: "./planned-mission-details.component.html",
    styleUrls: ["./planned-mission-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class PlannedMissionDetailsComponent {
    @Input() public set waitingMissions(value: Mission[] | undefined) {
        this.localStore.patchState({ waitingMissions: value });
    }
    @Input() public set rejectedMissions(value: Mission[] | undefined) {
        this.localStore.patchState({ rejectedMissions: value });
    }
    @Input() public set acceptedMissions(value: Mission[] | undefined) {
        this.localStore.patchState({ acceptedMissions: value });
    }
    @Input() public set selectedMissionRoute(value: MissionPlanRoute | undefined) {
        this.localStore.patchState({ selectedMissionRoute: value });
    }
    @Input() public set currentMissionPlanData(value: MissionPlanDataAndCapabilities | undefined) {
        this.localStore.patchState({ currentMissionPlanData: value });
    }
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set isMissionListProcessing(value: BooleanInput) {
        this.localStore.patchState({ isMissionListProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set shouldPilotPanelClose(value: BooleanInput) {
        this.localStore.patchState({ shouldPilotPanelClose: coerceBooleanProperty(value) });
    }
    @Input() public set areNewMissionsAvailable(value: BooleanInput) {
        this.localStore.patchState({ areNewMissionsAvailable: coerceBooleanProperty(value) });
    }
    @Input() public set isPlanRouteProcessing(value: BooleanInput) {
        this.localStore.patchState({ isPlanRouteProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set selectedTileId(value: string | undefined) {
        this.localStore.patchState({ selectedTileId: value });
    }
    @Input() public set missionDefaultsFilters(value: MissionFilters | undefined) {
        this.localStore.patchState({ missionDefaultsFilters: value });
    }
    @Input() public set zones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ zones: value });
    }
    @Input() public set currentPlanAnalysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ currentPlanAnalysisStatus: value });
    }
    @Input() public set dtmAreas(value: DtmArea[] | undefined) {
        this.localStore.patchState({ dtmAreas: value ?? [] });
    }
    @Input() public set workspacePermissions(value: WorkspacePermission[] | undefined) {
        this.localStore.patchState({ workspacePermissions: value });
    }
    @Input() public set selectedZoneId(value: string | undefined) {
        this.localStore.patchState({ selectedZoneId: value });
    }

    @Output() public readonly missionFilters = new EventEmitter<PhaseFilters>();
    @Output() public readonly missionPhaseChange = new EventEmitter<PhasePayloadData>();
    @Output() public readonly missionSelect = new EventEmitter<Mission>();
    @Output() public readonly priorityEdit = new EventEmitter<PriorityPayloadData>();
    @Output() public readonly newMissionAvailabilityClear = new EventEmitter<void>();
    @Output() public readonly noteUpdate = new EventEmitter<NoteData>();
    @Output() public readonly attachmentDownload = new EventEmitter<string>();
    @Output() public readonly messagePanelOpen = new EventEmitter<void>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    @ViewChildren(MissionFilterComponent) private missionFilterComponent!: QueryList<MissionFilterComponent>;

    @HostBinding("class.card-box") private cardBox = true;

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isMissionListProcessing$ = this.localStore.selectByKey("isMissionListProcessing");
    protected readonly areNewMissionsAvailable$ = this.localStore.selectByKey("areNewMissionsAvailable");
    protected readonly isPlanRouteProcessing$ = this.localStore.selectByKey("isPlanRouteProcessing");
    protected readonly shouldPilotPanelClose$ = this.localStore.selectByKey("shouldPilotPanelClose");
    protected readonly selectedTileId$ = this.localStore.selectByKey("selectedTileId");
    protected readonly missionDefaultsFilters$ = this.localStore.selectByKey("missionDefaultsFilters");
    protected readonly waitingMissions$ = this.localStore.selectByKey("waitingMissions").pipe(RxjsUtils.filterFalsy());
    protected readonly rejectedMissions$ = this.localStore.selectByKey("rejectedMissions").pipe(RxjsUtils.filterFalsy());
    protected readonly acceptedMissions$ = this.localStore.selectByKey("acceptedMissions").pipe(RxjsUtils.filterFalsy());
    protected readonly currentMissionPlanData$ = this.localStore.selectByKey("currentMissionPlanData");
    protected readonly currentPlanAnalysisStatus$ = this.localStore.selectByKey("currentPlanAnalysisStatus");
    protected readonly selectedMissionRoute$ = this.localStore.selectByKey("selectedMissionRoute");
    protected readonly workspacePermissions$ = this.localStore.selectByKey("workspacePermissions");
    protected readonly selectedZoneId$ = this.localStore.selectByKey("selectedZoneId");
    protected readonly zones$ = this.localStore.selectByKey("zones");
    protected readonly dtmAreas$ = this.localStore.selectByKey("dtmAreas");
    protected readonly MissionProcessingPhase = MissionProcessingPhase;
    protected readonly WAITING_SORTING_MAP = WAITING_SORTING_MAP;
    protected readonly REST_SORTING_MAP = REST_SORTING_MAP;

    constructor(private readonly localStore: LocalComponentStore<PlannedMissionDetailsComponentState>) {
        this.localStore.setState({
            waitingMissions: undefined,
            acceptedMissions: undefined,
            rejectedMissions: undefined,
            selectedMissionRoute: undefined,
            selectedTileId: undefined,
            isProcessing: false,
            isMissionListProcessing: false,
            areNewMissionsAvailable: false,
            isPlanRouteProcessing: false,
            missionDefaultsFilters: undefined,
            shouldPilotPanelClose: false,
            currentMissionPlanData: undefined,
            zones: undefined,
            currentPlanAnalysisStatus: undefined,
            dtmAreas: [],
            workspacePermissions: undefined,
            selectedZoneId: undefined,
        });
    }

    protected filterMission(filters: PlannedMissionFilters, phase: MissionProcessingPhase): void {
        this.missionFilters.emit({ ...filters, phase });
        this.localStore.patchState({ selectedTileId: undefined });
    }

    protected updateMissionFilters(event: MatTabChangeEvent): void {
        const missionPhase = PHASE_ARRAY[event.index];
        const missionFilterForm = this.missionFilterComponent.get(event.index)?.getMissionFilters();

        if (!missionFilterForm) {
            return;
        }

        this.missionFilters.emit({ ...missionFilterForm, phase: missionPhase });
    }
}
