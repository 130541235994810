<ng-container *ngIf="isSelectedMission$ | ngrxPush; else noNearbyMissionTemplate">
    <ng-container
        *ngrxLet="{
            otherMissions: otherMissions$,
            nearbyMissionRoute: nearbyMissionRoute$,
            isProcessing: isProcessing$,
            currentNearbyMissionData: currentNearbyMissionData$,
            isNearbyMissionRouteProcessing: isNearbyMissionRouteProcessing$,
            selectedNearbyMissionId: selectedNearbyMissionId$,
            zones: zones$,
            currentNearbyMissionAnalysisStatus: currentNearbyMissionAnalysisStatus$,
            areNearbyMissionsAvailable: areNearbyMissionsAvailable$,
            otherDefaultsFilters: otherDefaultsFilters$,
            workspacePermissions: workspacePermissions$
        } as vm"
    >
        <dtm-ui-loader-container [isShown]="vm.isProcessing">
            <supervisor-shared-lib-other-planned-mission-filters
                [defaultFilters]="vm.otherDefaultsFilters"
                (filtersChange)="filtersChange.emit($event)"
            ></supervisor-shared-lib-other-planned-mission-filters>
            <div class="nearby-mission-list">
                <supervisor-shared-lib-message
                    @slideIn
                    *ngIf="vm.areNearbyMissionsAvailable"
                    severity="info"
                    (messageClose)="nearbyMissionAvailabilityClear.emit()"
                >
                    {{ "dtmSupPlannedMissions.nearbyMissions.missionAvailableMessage" | transloco }}
                </supervisor-shared-lib-message>
                <supervisor-shared-lib-mission-info-panel
                    *ngFor="let mission of vm.otherMissions"
                    changeBasicDetailsLayout
                    [mission]="mission"
                    [isPlanRouteProcessing]="vm.isNearbyMissionRouteProcessing"
                    [isSelected]="vm.selectedNearbyMissionId === mission.missionId"
                    [workspacePermissions]="vm.workspacePermissions"
                    (click)="nearbyMissionSelect.emit(mission)"
                    (routeZoom)="routeZoom.next($event)"
                >
                    <div (click)="$event.stopPropagation()">
                        <!-- TODO replace it with <dtm-mission-mission-details> component - REJ-2976 -->
                        <dtm-ui-expandable-panel>
                            <h2 class="section-title" headerSlot>
                                {{ "dtmSupPlannedMissions.nearbyMissions.basicDataLabel" | transloco }}
                            </h2>
                            <dtm-mission-mission-data
                                [route]="vm.nearbyMissionRoute"
                                [missionPlanDataAndCapabilities]="vm.currentNearbyMissionData"
                                [operatorName]="mission.operatorName"
                            ></dtm-mission-mission-data>
                        </dtm-ui-expandable-panel>
                        <dtm-mission-flight-requirements
                            *ngIf="vm.currentNearbyMissionData?.plan as missionPlan"
                            [zones]="vm.zones"
                            [currentPlanAnalysisStatus]="vm.currentNearbyMissionAnalysisStatus"
                            [authorityAcceptation]="missionPlan.remarks.authorityAcceptation"
                            [formalJustification]="missionPlan.remarks.justification"
                            [isProcessing]="vm.isProcessing"
                            [shouldDisplayMessageConfirmation]="false"
                            [missionContext]="MissionContextType.Supervisor"
                            (zoneSelect)="zoneSelect.emit($event)"
                        ></dtm-mission-flight-requirements>
                        <dtm-ui-expandable-panel>
                            <h4 class="section-title" headerSlot>
                                {{ "dtmSupPlannedMissions.nearbyMissions.routeDetailsLabel" | transloco }}
                            </h4>
                            <dtm-ui-route-details [route]="vm.nearbyMissionRoute"></dtm-ui-route-details>
                        </dtm-ui-expandable-panel>
                    </div>
                </supervisor-shared-lib-mission-info-panel>
                <dtm-ui-no-results *ngIf="!vm.otherMissions?.length"></dtm-ui-no-results>
            </div>
        </dtm-ui-loader-container>
    </ng-container>
</ng-container>
<ng-template #noNearbyMissionTemplate>{{ "dtmSupPlannedMissions.nearbyMissions.missionPlanNotSelectedLabel" | transloco }}</ng-template>
