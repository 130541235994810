export interface SupUserProfile {
    userId: string;
    workspaces: SupWorkspace[];
}

export interface SupWorkspace {
    id: string;
    name: string;
    imageUrl: string;
    isManageRestOfCountryAllowed: boolean;
    dtms: { designator: string }[];
    authorityUnits: {
        id: string;
        name: string;
        zoneDesignator: string;
        type: AuthorityUnitType;
    }[];
    permissions: WorkspacePermission[];
}

export enum SupUserErrorType {
    CannotFetchUserProfile = "CannotFetchUserProfile",
    CannotGetAirspaceElements = "CannotGetAirspaceElements",
}

export enum WorkspacePermission {
    PlannedMissions = "PlannedMissions",
    OperationalSituation = "OperationalSituation",
    PriorityChanges = "PriorityChanges",
    Monitoring = "Monitoring",
    PermissionManagement = "PermissionManagement",
}

export enum AuthorityUnitType {
    Dtm = "DTM",
    AtzInDtm = "ATZ_IN_DTM",
    Other = "OTHER",
}
