import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { DEFAULT_DEBOUNCE_TIME, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, tap } from "rxjs";
import { GeographicalZone, InfoZone } from "../../../models/operational.situation.models";
import { ZoneFiltersData, ZoneObjectType, infoZoneIconMap, sortZoneCriteria } from "../../../models/temporary-zones.models";

interface FiltersForm {
    sort: FormControl<string | null>;
    name: FormControl<string | null>;
    geographicalZoneType: FormControl<GeographicalZone[] | null>;
    infoZoneType: FormControl<InfoZone | null>;
    zoneObjectType: FormControl<ZoneObjectType | null>;
    dateFrom: FormControl<Date | null>;
    dateTo: FormControl<Date | null>;
    timeFrom: FormControl<Date | null>;
    timeTo: FormControl<Date | null>;
}

interface ZoneFiltersComponentState {
    filtersCounter: number;
}

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-zone-filters",
    templateUrl: "./zone-filters.component.html",
    styleUrls: ["./zone-filters.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ZoneFiltersComponent {
    @Output() public readonly filtersChange = new EventEmitter<ZoneFiltersData>();

    protected readonly ZoneObjectType = ZoneObjectType;
    protected readonly infoZoneValues = infoZoneIconMap;
    protected readonly sortZoneCriteria = sortZoneCriteria;
    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly GeographicalZone = GeographicalZone;

    protected readonly filtersCounter$ = this.localStore.selectByKey("filtersCounter");

    protected readonly filtersForm = new FormGroup<FiltersForm>(
        {
            sort: new FormControl(null),
            name: new FormControl(null),
            geographicalZoneType: new FormControl(null),
            infoZoneType: new FormControl(null),
            zoneObjectType: new FormControl(null),
            dateFrom: new FormControl(null),
            dateTo: new FormControl(null),
            timeFrom: new FormControl(null),
            timeTo: new FormControl(null),
        },
        { validators: this.customFormValidator() }
    );

    constructor(
        private readonly localStore: LocalComponentStore<ZoneFiltersComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            filtersCounter: 0,
        });

        this.listenFiltersChange();
    }

    protected resetForm(): void {
        this.filtersForm.reset();
    }

    private customFormValidator(): ValidatorFn {
        return (filtersForm) => {
            const timeFromError =
                !filtersForm.get("dateFrom")?.value && filtersForm.get("timeFrom")?.value ? { requiredDateField: true } : null;
            const timeToError = !filtersForm.get("dateTo")?.value && filtersForm.get("timeTo")?.value ? { requiredDateField: true } : null;

            filtersForm.get("timeFrom")?.setErrors(timeFromError);
            filtersForm.get("timeTo")?.setErrors(timeToError);

            return timeFromError || timeToError;
        };
    }

    private listenFiltersChange(): void {
        this.filtersForm.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                tap(() => {
                    this.localStore.patchState({ filtersCounter: this.calculateFiltersCount() });
                }),
                untilDestroyed(this)
            )
            .subscribe(() => {
                if (this.filtersForm.invalid) {
                    return;
                }

                this.filtersChange.emit(this.filtersForm.getRawValue());
            });
    }

    private calculateFiltersCount(): number {
        return Object.values(this.filtersForm.value).filter(FunctionUtils.isTruthy).length;
    }
}
