<dtm-ui-expandable-panel
    [isExpanded]="false"
    [hasHeaderSeparator]="false"
    class="messages"
    *ngrxLet="{ filtersCounter: filtersCounter$ } as vm"
>
    <h2 headerSlot>
        <div class="header">
            {{ "dtmSupOperationalSituation.zoneFilters.headerLabel" | transloco : { value: vm.filtersCounter } }}
            <button type="button" class="button-secondary" *ngIf="vm.filtersCounter" (click)="$event.stopPropagation(); resetForm()">
                {{ "dtmSupOperationalSituation.zoneFilters.clearFiltersButtonLabel" | transloco }}
            </button>
        </div>
    </h2>
    <form [formGroup]="filtersForm" *ngrxLet="datePickerPlaceholder$ as datePickerPlaceholder">
        <dtm-ui-select-field
            formControlName="sort"
            [isClearable]="true"
            [placeholder]="'dtmSupOperationalSituation.zoneFilters.selectPlaceholder' | transloco"
        >
            <label>{{ "dtmSupOperationalSituation.zoneFilters.sortLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let sort of sortZoneCriteria" [value]="sort.direction">
                {{ "dtmSupOperationalSituation.zoneFilters.sortZoneValueLabel" | transloco : { value: sort.property } }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>
        <dtm-ui-input-field>
            <label> {{ "dtmSupOperationalSituation.zoneFilters.nameLabel" | transloco }}</label>
            <input
                formControlName="name"
                matInput
                type="text"
                [placeholder]="'dtmSupOperationalSituation.zoneFilters.namePlaceholder' | transloco"
            />
            <div class="field-suffix"><dtm-ui-icon name="search"></dtm-ui-icon></div>
        </dtm-ui-input-field>
        <dtm-ui-select-field
            [isClearable]="true"
            [multiple]="true"
            [placeholder]="'dtmSupOperationalSituation.temporaryZonesForm.chooseLabel' | transloco"
            formControlName="geographicalZoneType"
        >
            <label>{{ "dtmSupOperationalSituation.temporaryZonesForm.zoneTypeLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let zone of GeographicalZone | keyvalue" [value]="zone.value">
                {{ "dtmSupOperationalSituation.temporaryZonesForm.zoneTypeValueSelect" | transloco : { value: zone.value } }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>
        <dtm-ui-select-field
            [isClearable]="true"
            [multiple]="true"
            [placeholder]="'dtmSupOperationalSituation.zoneFilters.selectPlaceholder' | transloco"
            formControlName="infoZoneType"
        >
            <label>{{ "dtmSupOperationalSituation.zoneFilters.zoneTypeLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let infoZoneType of infoZoneValues" [value]="infoZoneType.type">
                <div class="select-value">
                    <dtm-ui-icon [name]="infoZoneType.icon"></dtm-ui-icon>
                    {{ "dtmSupOperationalSituation.zoneInfo.infoZoneType" | transloco : { value: infoZoneType.type } }}
                </div>
            </dtm-ui-select-option>
        </dtm-ui-select-field>
        <dtm-ui-select-field
            [isClearable]="true"
            [placeholder]="'dtmSupOperationalSituation.zoneFilters.selectPlaceholder' | transloco"
            formControlName="zoneObjectType"
        >
            <label>{{ "dtmSupOperationalSituation.zoneFilters.objectTypeLabel" | transloco }}</label>
            <dtm-ui-select-option *ngFor="let type of ZoneObjectType | keyvalue" [value]="type.value">
                {{ "dtmSupOperationalSituation.zoneInfo.zoneObjectValueLabel" | transloco : { value: type.value } }}
            </dtm-ui-select-option>
        </dtm-ui-select-field>
        <div class="zone-time-range">
            <dtm-ui-date-field>
                <label>{{ "dtmSupOperationalSituation.zoneFilters.fromDateLabel" | transloco }}</label>
                <input formControlName="dateFrom" [matDatepicker]="dateFromPicker" matInput [placeholder]="datePickerPlaceholder" />
                <mat-datepicker #dateFromPicker></mat-datepicker>
            </dtm-ui-date-field>
            <dtm-ui-time-field formControlName="timeFrom">
                <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.timeFrom; name: 'requiredDateField'">
                    {{ "dtmSupOperationalSituation.zoneFilters.timeFieldValidationError" | transloco }}
                </div>
            </dtm-ui-time-field>
        </div>
        <div class="zone-time-range">
            <dtm-ui-date-field>
                <label>{{ "dtmSupOperationalSituation.zoneFilters.toDateLabel" | transloco }}</label>
                <input formControlName="dateTo" [matDatepicker]="dateToPicker" matInput [placeholder]="datePickerPlaceholder" />
                <mat-datepicker #dateToPicker></mat-datepicker>
            </dtm-ui-date-field>
            <dtm-ui-time-field formControlName="timeTo">
                <div class="field-error" *dtmUiFieldHasError="filtersForm.controls.timeTo; name: 'requiredDateField'">
                    {{ "dtmSupOperationalSituation.zoneFilters.timeFieldValidationError" | transloco }}
                </div>
            </dtm-ui-time-field>
        </div>
    </form>
</dtm-ui-expandable-panel>
