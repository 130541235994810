<ng-container
    *ngrxLet="{
        isProcessing: isProcessing$,
        routeData: routeData$,
        drawableFeatures: drawableFeatures$,
        flights: flights$,
        trajectories: trajectories$,
        violations: violations$
    } as vm"
>
    <dtm-ui-loader [isShown]="vm.isProcessing"></dtm-ui-loader>
    <ac-map dtmMapPointerManager dtmMapCesiumCleanupOnDestroy>
        <dtm-map-azure-maps-layer [options]="AZURE_MAPS_LAYER_OPTIONS"></dtm-map-azure-maps-layer>
        <dtm-map-cylinders-editor [show]="true" [labelsTemplate]="labelsTemplate"></dtm-map-cylinders-editor>
        <dtm-map-prisms-editor [show]="true" [labelsTemplate]="labelsTemplate"></dtm-map-prisms-editor>
        <dtm-map-polylines3d-editor [show]="true" [labelTemplate]="labelsTemplate"></dtm-map-polylines3d-editor>
        <dtm-map-route-viewer
            [routeData]="vm.routeData"
            [drawableFeatures]="vm.drawableFeatures"
            [nearbyMissionsDrawableFeatures]="vm.drawableFeatures"
            [flightPositionUpdate]="vm.flights"
            [flightPinTemplate]="flightPinTemplate"
            [trajectories]="vm.trajectories"
            [dataIdentifierKey]="'missionId'"
            (visibleAreaChanged)="visibleAreaChanged.next($event)"
        ></dtm-map-route-viewer>
        <div class="layers-controls">
            <dtm-map-layers-with-controls
                [missionTimeRange]="missionTimeRange$ | ngrxPush"
                [shouldShowAreaBuffers]="true"
                [viewControls]="VIEW_CONTROLS"
                [viewControlsValue]="VIEW_CONTROLS_DEFAULT_VALUES"
                [routes]="vm.routeData | invoke : getRoutesFromRouteData"
                (viewControlsValueChange)="isPathVisibleControl.setValue(!!$event.Path)"
                [timeSettingOptions]="zoneTimeSettingOptions$ | ngrxPush"
            ></dtm-map-layers-with-controls>
        </div>
        <supervisor-shared-lib-draft-area-layer
            [isProcessing]="isZoneProcessing$ | ngrxPush"
            [area]="area$ | ngrxPush"
        ></supervisor-shared-lib-draft-area-layer>
        <dtm-map-lib-checkin-area-layer
            [isProcessing]="isProcessing$ | ngrxPush"
            [checkin]="checkin$ | ngrxPush"
            [checkins]="checkins$ | ngrxPush"
        >
        </dtm-map-lib-checkin-area-layer>
    </ac-map>

    <div class="overlay-container">
        <dtm-map-controls></dtm-map-controls>
    </div>
    <ng-template #flightPinTemplate let-data>
        <dtm-map-flight-pin
            [aircraftEntity]="data"
            [isUserAircraft]="data.trackerIdentifier | invoke : isUserAircraft : vm.routeData"
            [violationClass]="vm.violations | invoke : mapViolationToClass : data.trackerIdentifier"
            (flightPositionUpdatesEnrich)="$event ? flightPositionUpdatesEnrich.next(data.trackerIdentifier) : null"
        ></dtm-map-flight-pin>
    </ng-template>

    <ng-template #labelsTemplate let-label>
        <div class="label-pin default-label" *ngIf="label.show && label.text" [class.radius-label]="label.id === 'radius'">
            {{ label.text }}
        </div>
    </ng-template>
</ng-container>
