<ng-container
    *ngrxLet="{
        isProcessing: isProcessing$,
        zones: zones$,
        currentMissionPlanData: currentMissionPlanData$,
        currentPlanAnalysisStatus: currentPlanAnalysisStatus$,
        selectedMissionRoute: selectedMissionRoute$,
        selectedTileId: selectedTileId$,
        isPlanRouteProcessing: isPlanRouteProcessing$,
        isMissionListProcessing: isMissionListProcessing$,
        missionDefaultsFilters: missionDefaultsFilters$,
        dtmAreas: dtmAreas$,
        workspacePermissions: workspacePermissions$,
        selectedZoneId: selectedZoneId$,
    } as vm"
>
    <div class="tab-container dtm-tabs card-box">
        <mat-tab-group *ngIf="!vm.isMissionListProcessing" color="accent" (focusChange)="updateMissionFilters($event)">
            <mat-tab
                *ngrxLet="waitingMissions$ as waitingMissions"
                [label]="'dtmSupPlannedMissions.plannedMissionDetails.waitingTabTitle' | transloco : { count: waitingMissions.length }"
            >
                <div class="tab-content">
                    <supervisor-shared-lib-mission-filter
                        [missionDefaultsFilters]="vm.missionDefaultsFilters"
                        [sortingMap]="WAITING_SORTING_MAP"
                        [dtmAreas]="vm.dtmAreas"
                        (missionFilters)="filterMission($event, MissionProcessingPhase.Waiting)"
                    ></supervisor-shared-lib-mission-filter>
                    <supervisor-shared-lib-message
                        severity="info"
                        *ngIf="waitingMissions.length && (areNewMissionsAvailable$ | ngrxPush)"
                        (messageClose)="newMissionAvailabilityClear.emit()"
                        @slideIn
                    >
                        {{ "dtmSupPlannedMissions.plannedMissionDetails.newPlansAvailableLabel" | transloco }}
                    </supervisor-shared-lib-message>
                    <supervisor-shared-lib-phase-description
                        canEdit
                        [zones]="vm.zones"
                        [isProcessing]="vm.isProcessing"
                        [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                        [shouldPilotPanelClose]="shouldPilotPanelClose$ | ngrxPush"
                        [selectedMissionRoute]="vm.selectedMissionRoute"
                        [isPlanRouteProcessing]="vm.isPlanRouteProcessing"
                        [selectedTileId]="vm.selectedTileId"
                        [missions]="waitingMissions"
                        [currentMissionPlanData]="vm.currentMissionPlanData"
                        [workspacePermissions]="vm.workspacePermissions"
                        [selectedZoneId]="vm.selectedZoneId"
                        (missionSelect)="missionSelect.emit($event)"
                        (missionPhaseChange)="missionPhaseChange.emit($event)"
                        (priorityEdit)="priorityEdit.emit($event)"
                        (noteUpdate)="noteUpdate.emit($event)"
                        (attachmentDownload)="attachmentDownload.emit($event)"
                        (messagePanelOpen)="messagePanelOpen.emit()"
                        (zoneSelect)="zoneSelect.emit($event)"
                    ></supervisor-shared-lib-phase-description>
                </div>
            </mat-tab>
            <mat-tab
                *ngrxLet="acceptedMissions$ as acceptedMissions"
                [label]="'dtmSupPlannedMissions.plannedMissionDetails.approvedTabTitle' | transloco : { count: acceptedMissions.length }"
            >
                <div class="tab-content">
                    <supervisor-shared-lib-mission-filter
                        [missionDefaultsFilters]="vm.missionDefaultsFilters"
                        [sortingMap]="REST_SORTING_MAP"
                        [dtmAreas]="vm.dtmAreas"
                        (missionFilters)="filterMission($event, MissionProcessingPhase.Accepted)"
                    ></supervisor-shared-lib-mission-filter>
                    <supervisor-shared-lib-phase-description
                        [zones]="vm.zones"
                        [isProcessing]="vm.isProcessing"
                        [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                        [selectedMissionRoute]="vm.selectedMissionRoute"
                        [currentMissionPlanData]="vm.currentMissionPlanData"
                        [isPlanRouteProcessing]="vm.isPlanRouteProcessing"
                        [missions]="acceptedMissions"
                        [selectedTileId]="selectedTileId$ | ngrxPush"
                        [selectedZoneId]="vm.selectedZoneId"
                        [workspacePermissions]="vm.workspacePermissions"
                        (missionSelect)="this.missionSelect.emit($event)"
                        (zoneSelect)="zoneSelect.emit($event)"
                    ></supervisor-shared-lib-phase-description>
                </div>
            </mat-tab>
            <mat-tab
                *ngrxLet="rejectedMissions$ as rejectedMissions"
                [label]="'dtmSupPlannedMissions.plannedMissionDetails.rejectedTabTitle' | transloco : { count: rejectedMissions.length }"
            >
                <div class="tab-content">
                    <supervisor-shared-lib-mission-filter
                        [missionDefaultsFilters]="vm.missionDefaultsFilters"
                        [sortingMap]="REST_SORTING_MAP"
                        [dtmAreas]="vm.dtmAreas"
                        (missionFilters)="filterMission($event, MissionProcessingPhase.Rejected)"
                    ></supervisor-shared-lib-mission-filter>
                    <supervisor-shared-lib-phase-description
                        [zones]="vm.zones"
                        [isProcessing]="vm.isProcessing"
                        [currentPlanAnalysisStatus]="vm.currentPlanAnalysisStatus"
                        [selectedMissionRoute]="vm.selectedMissionRoute"
                        [currentMissionPlanData]="vm.currentMissionPlanData"
                        [isPlanRouteProcessing]="vm.isPlanRouteProcessing"
                        [missions]="rejectedMissions"
                        [workspacePermissions]="vm.workspacePermissions"
                        [selectedTileId]="vm.selectedTileId"
                        [selectedZoneId]="vm.selectedZoneId"
                        (missionSelect)="this.missionSelect.emit($event)"
                        (zoneSelect)="zoneSelect.emit($event)"
                    ></supervisor-shared-lib-phase-description>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-container>
