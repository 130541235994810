import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { supPermissionGuard } from "../shared/guards/sup-permission.guard";
import { WorkspacePermission } from "../sup-user/index";
import { OperationalSituationContainerComponent } from "./components/operational-situation-container/operational-situation-container.component";

const routes: Routes = [
    {
        path: "operational-situation",
        canActivate: [DtmRoleGuard, supPermissionGuard([WorkspacePermission.OperationalSituation])],
        component: OperationalSituationContainerComponent,
        data: {
            titleTranslationKey: "dtmSupOperationalSituation.routeTitles.operationalSituation",
            className: "no-padding",
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OperationalSituationRoutingModule {}
