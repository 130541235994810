export namespace GlobalAlarmActions {
    export class GlobalAlarmWatch {
        public static readonly type = "[SUP - Shared] Global alarm watch";
    }

    export class DeleteAlarm {
        public static readonly type = "[SUP - Shared] Delete global alarm";
        constructor(public alarmId: string) {}
    }
}
