import { AuthorityUnitType, SupUserProfile, WorkspacePermission } from "../models/sup-user.models";

export interface SupUserProfileResponseBody {
    userId: string;
    workspaces: {
        id: string;
        name: string;
        imageUrl: string;
        manageRestOfCountry: boolean;
        dtms: { designator: string }[];
        authorityUnits: {
            id: string;
            name: string;
            zoneDesignator: string;
            type: AuthorityUnitType;
        }[];
    }[];
}

export function convertSupUserProfileResponseBodyToSupUserProfile(response: SupUserProfileResponseBody): SupUserProfile {
    return {
        userId: response.userId,
        workspaces: response.workspaces.map((workspace) => {
            const permissions = [WorkspacePermission.PlannedMissions, WorkspacePermission.OperationalSituation];

            // TODO: DTM-5283 temporary until backend will provide permissions
            if (workspace.name === "SUP DTM") {
                permissions.push(
                    WorkspacePermission.PriorityChanges,
                    WorkspacePermission.PermissionManagement,
                    WorkspacePermission.Monitoring
                );
            }

            return {
                id: workspace.id,
                name: workspace.name,
                imageUrl: workspace.imageUrl,
                isManageRestOfCountryAllowed: workspace.manageRestOfCountry,
                dtms: workspace.dtms,
                authorityUnits: workspace.authorityUnits.map((authorityUnit) => ({
                    id: authorityUnit.id,
                    name: authorityUnit.name,
                    zoneDesignator: authorityUnit.zoneDesignator,
                    type: authorityUnit.type,
                })),
                permissions,
            };
        }),
    };
}
