import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AirspaceElement, AirspaceElementsInfo } from "@dtm-frontend/shared/map/geo-zones";
import { MissionPlanAnalysisStatus, MissionPlanDataAndCapabilities, MissionType } from "@dtm-frontend/shared/mission";
import { GeoJSON, MissionSegmentStatus } from "@dtm-frontend/shared/ui";
import { MissionData } from "@dtm-frontend/shared/ui/tactical";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, map } from "rxjs";
import { Checkin, CheckinStatus, MissionStatus, OperationType, ProceedingMission } from "../../models/operational.situation.models";
import { MissionTypeFilters } from "./mission-type-filter/mission-type-filter.component";

interface ProceedingMissionsListComponentState {
    proceedingMissions: ProceedingMission[];
    filteredProceedingMissions: ProceedingMission[];
    checkinList: Checkin[];
    filteredCheckinList: Checkin[];
    filters: {
        missionType: MissionTypeFilters;
        missionStatus: MissionStatus;
    };
    selectedOperationId: string | undefined;
    selectedMission: MissionData | undefined;
    currentMissionPlanData: MissionPlanDataAndCapabilities | undefined;
    zones: AirspaceElementsInfo | undefined;
    currentPlanAnalysisStatus: MissionPlanAnalysisStatus | undefined;
    selectedMissionSegmentStatuses: MissionSegmentStatus[] | undefined;
}

const MISSION_TYPE_FILTERS_DEFAULTS: MissionTypeFilters = {
    vlos: true,
    bvlos: true,
    checkin: true,
};

@UntilDestroy()
@Component({
    selector: "supervisor-shared-lib-proceeding-missions-list[proceedingMissions]",
    templateUrl: "./proceeding-missions-list.component.html",
    styleUrls: ["./proceeding-missions-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProceedingMissionsListComponent {
    @Input() public set proceedingMissions(value: ProceedingMission[] | undefined) {
        this.localStore.patchState({ proceedingMissions: value ?? [], filteredProceedingMissions: value ?? [] });
    }
    @Input() public set checkinList(value: Checkin[] | undefined) {
        this.localStore.patchState({ checkinList: value ?? [], filteredCheckinList: value ?? [] });
    }
    @Input() public set selectedOperationId(value: string | undefined) {
        this.localStore.patchState({ selectedOperationId: value });
    }
    @Input() public set currentMissionPlanData(value: MissionPlanDataAndCapabilities | undefined) {
        this.localStore.patchState({ currentMissionPlanData: value });
    }
    @Input() public set selectedMission(value: MissionData | undefined) {
        this.localStore.patchState({ selectedMission: value });
    }
    @Input() public set zones(value: AirspaceElementsInfo | undefined) {
        this.localStore.patchState({ zones: value });
    }
    @Input() public set currentPlanAnalysisStatus(value: MissionPlanAnalysisStatus | undefined) {
        this.localStore.patchState({ currentPlanAnalysisStatus: value });
    }
    @Input() public set selectedMissionSegmentStatuses(value: MissionSegmentStatus[] | undefined) {
        this.localStore.patchState({ selectedMissionSegmentStatuses: value ?? [] });
    }

    @Output() public readonly missionReject = new EventEmitter<string>();
    @Output() public readonly missionTimeChange = new EventEmitter<ProceedingMission>();
    @Output() public readonly missionSelect = new EventEmitter<ProceedingMission>();
    @Output() public readonly checkinSelect = new EventEmitter<Checkin>();
    @Output() public readonly missionRoadZoom = new EventEmitter<string | undefined>();
    @Output() public readonly checkinZoom = new EventEmitter<GeoJSON>();
    @Output() public readonly zoneSelect = new EventEmitter<AirspaceElement>();

    protected readonly filteredProceedingMissions$ = this.localStore.selectByKey("filteredProceedingMissions");
    protected readonly filteredCheckinList$ = this.localStore.selectByKey("filteredCheckinList");
    protected readonly selectedOperationId$ = this.localStore.selectByKey("selectedOperationId");
    protected readonly currentMissionPlanData$ = this.localStore.selectByKey("currentMissionPlanData");
    protected readonly currentPlanAnalysisStatus$ = this.localStore.selectByKey("currentPlanAnalysisStatus");
    protected readonly selectedMission$ = this.localStore.selectByKey("selectedMission");
    protected readonly zones$ = this.localStore.selectByKey("zones");
    protected readonly selectedMissionSegmentStatuses$ = this.localStore.selectByKey("selectedMissionSegmentStatuses");

    protected readonly operationList$ = combineLatest([this.filteredProceedingMissions$, this.filteredCheckinList$]).pipe(
        map(([proceedingList, checkinList]) =>
            [...proceedingList, ...checkinList].sort((left, right) => {
                if (left.flightStartAt && right.flightStartAt) {
                    return right.flightStartAt.getTime() - left.flightStartAt.getTime();
                }

                return 0;
            })
        )
    );
    protected readonly OperationType = OperationType;
    protected readonly MISSION_TYPE_FILTERS_DEFAULTS = MISSION_TYPE_FILTERS_DEFAULTS;

    constructor(protected readonly localStore: LocalComponentStore<ProceedingMissionsListComponentState>) {
        this.localStore.setState({
            proceedingMissions: [],
            checkinList: [],
            filteredProceedingMissions: [],
            filteredCheckinList: [],
            filters: { missionType: MISSION_TYPE_FILTERS_DEFAULTS, missionStatus: MissionStatus.All },
            selectedOperationId: undefined,
            selectedMission: undefined,
            currentMissionPlanData: undefined,
            zones: undefined,
            currentPlanAnalysisStatus: undefined,
            selectedMissionSegmentStatuses: undefined,
        });

        this.localStore
            .selectByKey("filters")
            .pipe(untilDestroyed(this))
            .subscribe((filters) => {
                const proceedMissions = this.localStore.selectSnapshotByKey("proceedingMissions");
                const checkinList = this.localStore.selectSnapshotByKey("checkinList");

                const filteredProceedingMissions = proceedMissions.filter(
                    (mission) =>
                        this.hasMissionStatus(mission.missionStatus, filters.missionStatus) &&
                        this.hasMissionType(mission.missionType, filters.missionType)
                );

                const filteredCheckins = checkinList.filter(
                    (checkin) =>
                        filters.missionType.checkin &&
                        this.hasMissionStatus(this.mapCheckinStatusToMissionStatus(checkin.status), filters.missionStatus) &&
                        this.hasMissionType(checkin.checkinType, filters.missionType)
                );

                this.localStore.patchState({
                    filteredProceedingMissions: filteredProceedingMissions,
                    filteredCheckinList: filteredCheckins,
                });
            });
    }

    private hasMissionStatus(missionStatus: MissionStatus, filterMissionStatus: MissionStatus): boolean {
        return filterMissionStatus === MissionStatus.All || missionStatus === filterMissionStatus;
    }

    private hasMissionType(currentMissionType: MissionType, missionType: MissionTypeFilters): boolean {
        return (
            (currentMissionType === MissionType.VLOS && missionType.vlos) || (currentMissionType === MissionType.BVLOS && missionType.bvlos)
        );
    }

    private mapCheckinStatusToMissionStatus(checkinStatus: CheckinStatus): MissionStatus {
        return {
            [CheckinStatus.Submitted]: MissionStatus.Activated,
            [CheckinStatus.InRealization]: MissionStatus.Started,
            [CheckinStatus.All]: MissionStatus.All,
            [CheckinStatus.Expired]: MissionStatus.Canceled,
            [CheckinStatus.Completed]: MissionStatus.Finished,
        }[checkinStatus];
    }

    protected filterMissionsType(missionType: MissionTypeFilters): void {
        this.localStore.patchState(({ filters }) => ({
            filters: {
                ...filters,
                missionType: {
                    ...missionType,
                },
            },
        }));
    }

    protected filterByStatus(status: MissionStatus): void {
        this.localStore.patchState(({ filters }) => ({
            filters: {
                ...filters,
                missionStatus: status,
            },
        }));
    }
}
